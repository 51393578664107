import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export function CampaignPrivateRoutes() {
  const authReducer = useSelector((state) => state.authReducer);
  const userRole = authReducer?.userData?.role;
  const orgReducer = useSelector((state) => state.orgReducer);
  const subscriptionReducer = useSelector((state) => state.subscriptionReducer);
  const isMarketing = subscriptionReducer?.isMarketing;
  const healthAnalysisID = orgReducer?.healthAnalysisID?.uid;

  return userRole === "Bond-Admin" || isMarketing ? (
    <Outlet />
  ) : (
    <Navigate to={`/bank/health-analysis/report/${healthAnalysisID}`} />
  );
}

export default CampaignPrivateRoutes;
