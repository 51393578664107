import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const ghostConsumerAnalysisSlice = createApi({
  reducerPath: "analysis_ghost_consumer",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["ghost_consumer"],
  endpoints: (build) => ({
    getGhostConsumerSummary: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=ghost_consumer&view=summary&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),

    getGhostConsumerExpended: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=ghost_consumer&view=expand&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetGhostConsumerSummaryQuery,
  useGetGhostConsumerExpendedQuery,
} = ghostConsumerAnalysisSlice;
