export const EMAIL_CAMPAIGN_ORG_ID =
  "8031c09d-bbfd-8d4c-e3d1-59b3484e7999::undefined";
// export const EMAIL_CAMPAIGN_ORG_ID = "33309668-45ff-7248-ef06-8d4890fd7206";
// export const EMAIL_CAMPAIGN_ORG_ID = "82633550-2b75-e8b2-8c35-f152dc8e503f";

export const EMAIL_CAMPAIGN_TEMPLATE_ID =
  "MWQ3ODVkMGQtZWFkOC03ZWQwLTFmOGUtOTdjMzNlZDI3MzdhOg==";
export const TEST_EMAIL_ARRAY = [
  {
    label: "kent@bond.ai",
    value: "kent@bond.ai",
  },
  {
    label: "jeff.norton@bond.ai",
    value: "jeff.norton@bond.ai",
  },
  {
    label: "bryce.mildon@bond.ai",
    value: "bryce.mildon@bond.ai",
  },
  {
    label: "anjali@bond.ai",
    value: "anjali@bond.ai",
  },
  {
    label: "pooja@bond.ai",
    value: "pooja@bond.ai",
  },
  {
    label: "varun@bond.ai",
    value: "varun@bond.ai",
  },
  {
    label: "sneha@bond.ai",
    value: "sneha@bond.ai",
  },
  {
    label: "jugal.baraiya@bond.ai",
    value: "jugal.baraiya@bond.ai",
  },
];

export const getFormattedDate = (date) => {
  const isoDate = date;
  const dateObject = new Date(isoDate);

  // Extract individual components for custom formatting
  const month = dateObject.toLocaleString("en-US", {
    month: "short",
  });
  const day = dateObject.getDate();
  let year = Number(dateObject.getFullYear());
  // const time = dateObject.toLocaleString("en-US", {
  //   hour: "numeric",
  //   minute: "numeric",
  //   hour12: true,
  // });

  // Combine the components into the desired format
  const finalFormat = `${month} ${day},  ${year}`;
  return finalFormat;
};

export const getFormattedDateType1 = (date) => {
  const dateObject = new Date(date);
  let day = String(dateObject.getDate()).padStart(2, "0");
  var month = dateObject.toLocaleString("default", {
    month: "short",
  });
  let year = dateObject.getFullYear().toString().substring(2);
  var hrs = dateObject.getHours();
  var mnts = dateObject.getMinutes();
  var AMPM = hrs >= 12 ? "PM" : "AM";
  hrs = hrs % 12;
  hrs = hrs ? hrs : 12;
  mnts = mnts < 10 ? "0" + mnts : mnts;
  var time = hrs + ":" + mnts + " " + AMPM;
  return (
    <span className="ps-0 ps-xxl-0">
      {" "}
      {month} {day}, '{year}, {time}{" "}
    </span>
  );
};
