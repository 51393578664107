import { globalError } from "./global-error-handler";
import { store } from "../app/store";
import { LogOut } from "../Redux/authSlice";
import axios from "axios";
import { toast } from "react-toastify";
import { toastResponse } from "../helper components/Toast/Toast";
import errormessages from "../assets/i18n/en.json";

export const serverUrl = process.env.REACT_APP_API_URL;

export async function GetFromServer(
  url,
  data = {},
  headers = {},
  params = {},
  config = {},
) {
  const state = store.getState();
  const authReducer = state.authReducer;

  axios.defaults.headers.common["Authorization"] =
    authReducer.isLogin === false
      ? headers
      : `${authReducer.userData.tokenType.toLowerCase()} ${
          authReducer?.userData?.token
        }`;
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  data = { ...data, mode: "cors" };
  try {
    if (navigator.onLine) {
      const response = await axios.get(url, {
        data,
        headers,
        params,
        ...config,
      });

      return response;
    } else {
      toast.error(
        toastResponse(
          "error",
          "There seems to be issues with internet connectivity. Please check your connection.",
        ),
      );
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      //   clearAndLogout()
      store.dispatch(LogOut());
    } else {
      const errorDetails = globalError(
        error?.response
          ? error?.response
          : {
              data: {
                status: "ERROR",
                error: error?.code,
              },
            },
      );

      if (error?.response?.status === 401) {
        toast.error(toastResponse("error", errormessages?.ERROR.CODE[401]));
      } else {
        toast.error(toastResponse("error", errorDetails?.message));
      }
      return Promise.reject({ ...error?.response, data: { ...errorDetails } });
    }
  }
}

export default GetFromServer;
