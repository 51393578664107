import { createSlice } from "@reduxjs/toolkit";

function sortObjectKeys(obj, direction) {
  let sortedKeys = [];
  if (direction === "accending") {
    sortedKeys = Object.keys(obj).sort((a, b) => a.localeCompare(b));
  } else {
    sortedKeys = Object.keys(obj).sort((a, b) => b.localeCompare(a));
  }
  for (let i = 0; i < sortedKeys.length; i++) {
    const key = sortedKeys[i];
    const value = obj[key];
    delete obj[key];
    obj[key] = value;
  }

  return obj;
}
function sortObjectTotal(obj, direction) {
  let sortedKeys = [];

  if (direction === "accending") {
    sortedKeys = Object.keys(obj).sort((a, b) => obj[a]?.total - obj[b]?.total);
  } else {
    sortedKeys = Object.keys(obj).sort((a, b) => obj[b]?.total - obj[a]?.total);
  }
  for (let i = 0; i < sortedKeys.length; i++) {
    const key = sortedKeys[i];
    const value = obj[key];
    delete obj[key];
    obj[key] = value;
  }

  return obj;
}
const initialState = {
  zone: {},
  data: [],
  totalUsers: 0,
  min: 0,
  max: 0,
  products: {},
  merchants: {},
  persona: {},
  selectedProduct: "",
  consumer: {},
  tempProduct: {},
  tempMerchants: {},
  filterObject: {
    textSearch: "",
    zone: [],
    persona: [],
    products: {},
    merchants: [],
    filteredData: [],
    min: 0,
    max: 0,
    consumer: [],
  },
};

export const filterOptionSlice = createSlice({
  name: "filterOptionSlice",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload.data;
      state.filterObject.filteredData = action.payload.data;
      // min max Calculation
      // console.log((action.payload.data).)
      let min = Math.floor(
        action.payload.data.reduce(
          (min, current) => Math.min(min, Number(current?.balance)),
          Number(action.payload.data[0].balance),
        ),
      );
      let max = Math.floor(
        action.payload.data.reduce(
          (max, current) => Math.max(max, Number(current?.balance)),
          0,
        ),
      );

      // Assigning to Redux
      state.min = min;
      state.max = max;
      state.filterObject.min = "e";
      state.filterObject.max = "e";
      // ZoneTotalCalculation
      if (action.payload.type === "CrossSellOpp") {
        state.zone.Green = action.payload.data.reduce(
          (acc, item) =>
            item.zone === "Green" ? acc + item?.crossSellConsumer : acc,
          0,
        );
        state.zone.Orange = action.payload.data.reduce(
          (acc, item) =>
            item.zone === "Orange" ? acc + item?.crossSellConsumer : acc,
          0,
        );
        state.zone.Red = action.payload.data.reduce(
          (acc, item) =>
            item.zone === "Red" ? acc + item?.crossSellConsumer : acc,
          0,
        );
      } else {
        state.zone.Green = action.payload.data.reduce(
          (acc, item) => (item.zone === "Green" ? acc + 1 : acc),
          0,
        );
        state.zone.Orange = action.payload.data.reduce(
          (acc, item) => (item.zone === "Orange" ? acc + 1 : acc),
          0,
        );
        state.zone.Red = action.payload.data.reduce(
          (acc, item) => (item.zone === "Red" ? acc + 1 : acc),
          0,
        );
      }

      // calculation products Object {abc:2,xyz:5}

      if (action.payload.type === "Secondary_Consumer") {
        let consumer = action.payload.data.reduce((acc, item) => {
          if (acc[item.consumerLabel]) {
            acc[item.consumerLabel].total = acc[item.consumerLabel].total + 1;
            acc[item.consumerLabel][item.zone].total =
              acc[item.consumerLabel][item.zone].total + 1;
            acc[item.consumerLabel][item.zone][item.topPersonas] = acc[
              item.consumerLabel
            ][item.zone][item.topPersonas]
              ? acc[item.consumerLabel][item.zone][item.topPersonas] + 1
              : 1;
          } else {
            acc[item.consumerLabel] = {
              total: 1,
              Red: { total: 0 },
              Green: { total: 0 },
              Orange: { total: 0 },
            };
            acc[item.consumerLabel][item.zone].total = 1;
            acc[item.consumerLabel][item.zone][item.topPersonas] = 1;
          }
          return acc;
        }, {});
        consumer = sortObjectKeys(consumer, "accending");
        state.consumer = consumer;
      } else if (action.payload.type === "CrossSellOpp") {
        let merchants = {};
        let products = action.payload.data.reduce((acc, item) => {
          // if not secondary card for merchants [merchantName:[]]
          if (acc[item.category]) {
            // If category already exisist in acc add 1 to total and check the merchant and
            //  add accordingly with count on them as well after spliting check if already exisit if not add
            acc[item.category].total =
              acc[item.category].total + item?.crossSellConsumer;
            // }
            if (item?.merchantName != null) {
              item?.merchantName?.split(",")?.map((merchantName) => {
                merchants = Object.keys(merchants).includes(
                  merchantName?.trim(),
                )
                  ? {
                      ...merchants,
                      [merchantName?.trim()]: {
                        ...merchants[merchantName?.trim()],
                        zone: merchants[merchantName?.trim()].zone.includes(
                          item.zone,
                        )
                          ? [...merchants[merchantName?.trim()].zone]
                          : [
                              ...merchants[merchantName?.trim()].zone,
                              item.zone,
                            ],
                        total: merchants[merchantName?.trim()].total + 1,
                      },
                    }
                  : {
                      ...merchants,
                      [merchantName?.trim()]: { total: 1, zone: [item.zone] },
                    };

                if (acc[item.category].merchantList[merchantName?.trim()]) {
                  acc[item.category].merchantList[merchantName?.trim()].total =
                    acc[item.category].merchantList[merchantName?.trim()]
                      .total + 1;
                  acc[item.category].merchantList[
                    merchantName?.trim()
                  ].personas[item.topPersonas] = Object.keys(
                    acc[item.category].merchantList[merchantName?.trim()]
                      .personas,
                  ).includes(item.topPersonas)
                    ? acc[item.category].merchantList[merchantName?.trim()]
                        .personas[item.topPersonas] + item?.crossSellConsumer
                    : item?.crossSellConsumer;
                } else {
                  acc[item.category].merchantList[merchantName?.trim()] = {
                    total: 1,
                    zone: [item.zone],
                    personas: { [item.topPersonas]: item?.crossSellConsumer },
                  };
                }
                if (
                  !acc[item.category].merchantList[
                    merchantName?.trim()
                  ].zone.includes(item.zone)
                ) {
                  acc[item.category].merchantList[
                    merchantName?.trim()
                  ].zone.push(item.zone);
                }

                return null;
              });
            }

            if (item?.topPersonas) {
              if (
                Object.keys(acc[item.category].personas).includes(
                  item.topPersonas,
                )
              ) {
                acc[item.category].personas = {
                  ...acc[item.category].personas,
                  [item?.topPersonas]:
                    acc[item.category].personas[item?.topPersonas] +
                    item?.crossSellConsumer,
                };
              } else {
                acc[item.category].personas = {
                  ...acc[item.category].personas,
                  [item?.topPersonas]: item?.crossSellConsumer,
                };
              }

              if (!acc[item.category].zone?.includes(item.zone)) {
                acc[item.category]?.zone.push(item.zone);
              }
            }
            acc[item.category].merchantList = sortObjectKeys(
              acc[item.category].merchantList,
              "accending",
            );
          } else {
            // if merchant is not already present then add it with the merchnat name array
            acc[item.category] = {
              total: item?.crossSellConsumer,
              merchantList: {},
              color: item.categoryColor,
              zone: [item.zone],
              personas: { [item.topPersonas]: item?.crossSellConsumer },
            };
            if (item?.merchantName != null) {
              item?.merchantName?.split(",")?.map((merchantName) => {
                merchants = Object.keys(merchants).includes(
                  merchantName?.trim(),
                )
                  ? {
                      ...merchants,
                      [merchantName?.trim()]: {
                        ...merchants[merchantName?.trim()],
                        zone: merchants[merchantName?.trim()].zone.includes(
                          item.zone,
                        )
                          ? [...merchants[merchantName?.trim()].zone]
                          : [
                              ...merchants[merchantName?.trim()].zone,
                              item.zone,
                            ],
                        total: merchants[merchantName?.trim()].total + 1,
                      },
                    }
                  : {
                      ...merchants,
                      [merchantName?.trim()]: { total: 1, zone: [item.zone] },
                    };
                acc[item.category].merchantList[merchantName.trim()] = {
                  total: 1,
                  zone: [item.zone],
                  personas: { [item.topPersonas]: item?.crossSellConsumer },
                };
                let tempList = sortObjectKeys(
                  acc[item.category].merchantList,
                  "accending",
                );
                acc[item.category].merchantList = tempList;
                return null;
              });
            }
          }

          return acc;
        }, {});
        //Assining to Redux
        products = sortObjectKeys(products, "accending");
        merchants = sortObjectKeys(merchants, "accending");
        state.merchants = merchants;
        state.products = products;
        state.tempProduct = products;
        state.tempMerchants = merchants;
      } else {
        let merchants = {};
        let products = action.payload.data.reduce((acc, item) => {
          // if not secondary card for merchants [merchantName:[]]
          if (acc[item.category]) {
            // If category already exisist in acc add 1 to total and check the merchant and
            //  add accordingly with count on them as well after spliting check if already exisit if not add
            // if(action.payload.type === "CrossSellOpp")
            // {
            //   acc[item.category].total = acc[item.category].total+item?.crossSellConsumer

            // }
            // else{
            acc[item.category].total = acc[item.category].total + 1;
            // }
            if (item?.merchantName != null) {
              item?.merchantName?.split(",")?.map((merchantName) => {
                merchants = Object.keys(merchants).includes(
                  merchantName?.trim(),
                )
                  ? {
                      ...merchants,
                      [merchantName?.trim()]: {
                        ...merchants[merchantName?.trim()],
                        zone: merchants[merchantName?.trim()].zone.includes(
                          item.zone,
                        )
                          ? [...merchants[merchantName?.trim()].zone]
                          : [
                              ...merchants[merchantName?.trim()].zone,
                              item.zone,
                            ],
                        total: merchants[merchantName?.trim()].total + 1,
                      },
                    }
                  : {
                      ...merchants,
                      [merchantName?.trim()]: { total: 1, zone: [item.zone] },
                    };
                if (acc[item.category].merchantList[merchantName?.trim()]) {
                  acc[item.category].merchantList[merchantName?.trim()].total =
                    acc[item.category].merchantList[merchantName?.trim()]
                      .total + 1;
                  acc[item.category].merchantList[
                    merchantName?.trim()
                  ].personas[item.topPersonas] = Object.keys(
                    acc[item.category].merchantList[merchantName?.trim()]
                      .personas,
                  ).includes(item.topPersonas)
                    ? acc[item.category].merchantList[merchantName?.trim()]
                        .personas[item.topPersonas] + 1
                    : 1;
                } else {
                  acc[item.category].merchantList[merchantName?.trim()] = {
                    total: 1,
                    zone: [item.zone],
                    personas: { [item.topPersonas]: 1 },
                  };
                }
                if (
                  !acc[item.category].merchantList[
                    merchantName?.trim()
                  ].zone.includes(item.zone)
                ) {
                  acc[item.category].merchantList[
                    merchantName?.trim()
                  ].zone.push(item.zone);
                }
                return null;
              });
            }
            if (item?.topPersonas) {
              if (
                Object.keys(acc[item.category].personas).includes(
                  item.topPersonas,
                )
              ) {
                acc[item.category].personas = {
                  ...acc[item.category].personas,
                  [item?.topPersonas]:
                    acc[item.category].personas[item?.topPersonas] + 1,
                };
              } else {
                acc[item.category].personas = {
                  ...acc[item.category].personas,
                  [item?.topPersonas]: 1,
                };
              }

              if (!acc[item.category].zone?.includes(item.zone)) {
                acc[item.category]?.zone.push(item.zone);
              }
              acc[item.category].merchantList = sortObjectKeys(
                acc[item.category].merchantList,
                "accending",
              );
            }
          } else {
            // if merchant is not already present then add it with the merchnat name array
            acc[item.category] = {
              total: 1,
              merchantList: {},
              color: item.categoryColor,
              zone: [item.zone],
              personas: { [item.topPersonas]: 1 },
            };
            if (item?.merchantName != null) {
              item.merchantName.split(",")?.map((merchantName) => {
                merchants = Object.keys(merchants).includes(
                  merchantName?.trim(),
                )
                  ? {
                      ...merchants,
                      [merchantName?.trim()]: {
                        ...merchants[merchantName?.trim()],
                        zone: merchants[merchantName?.trim()].zone.includes(
                          item.zone,
                        )
                          ? [...merchants[merchantName?.trim()].zone]
                          : [
                              ...merchants[merchantName?.trim()].zone,
                              item.zone,
                            ],
                        total: merchants[merchantName?.trim()].total + 1,
                      },
                    }
                  : {
                      ...merchants,
                      [merchantName?.trim()]: { total: 1, zone: [item.zone] },
                    };
                acc[item.category].merchantList[merchantName?.trim()] = {
                  total: 1,
                  zone: [item.zone],
                  personas: { [item.topPersonas]: 1 },
                };
                // acc[item.category].merchantList = sortObjectKeys(acc[item.category].merchantList,'accending')
                return null;
              });
            }
          }

          return acc;
        }, {});
        //Assining to Redux
        products = sortObjectKeys(products, "accending");
        merchants = sortObjectKeys(merchants, "accending");
        state.products = products;
        state.tempProduct = products;
        state.merchants = merchants;
        state.tempMerchants = merchants;
      }

      // state.filterObject.products=products
      //Calculating Persona
      let personas = action.payload.data.reduce((acc, item) => {
        if (action.payload.type === "CrossSellOpp") {
          if (acc[item.topPersonas]) {
            acc[item.topPersonas].total =
              acc[item.topPersonas].total + item?.crossSellConsumer;
            acc[item.topPersonas].zone = acc[item.topPersonas].zone.includes(
              item.zone,
            )
              ? acc[item.topPersonas].zone
              : [item.zone, ...acc[item.topPersonas].zone];
          } else {
            acc[item.topPersonas] = {
              total: item?.crossSellConsumer,
              zone: [item.zone],
            };
          }
          return acc;
        } else {
          if (acc[item.topPersonas]) {
            acc[item.topPersonas].total = acc[item.topPersonas].total + 1;
            acc[item.topPersonas].zone = acc[item.topPersonas].zone.includes(
              item.zone,
            )
              ? acc[item.topPersonas].zone
              : [item.zone, ...acc[item.topPersonas].zone];
          } else {
            acc[item.topPersonas] = { total: 1, zone: [item.zone] };
          }
          return acc;
        }
      }, {});
      delete personas["NA"];
      // assiging to redux
      personas = sortObjectKeys(personas, "decending");
      state.persona = personas;
      //  state.filterObject.persona = personas
      //  state.selectedProduct ="Mortgage"
    },
    setStateDirect: (state, action) => {
      state.zone = action.payload.zone;
      state.data = action.payload.data;
      state.totalUsers = action.payload.totalUsers;
      state.min = action.payload.min;
      state.max = action.payload.max;
      state.products = action.payload.products;
      state.merchants = action.payload.merchants;
      state.persona = action.payload.persona;
      state.selectedProduct = action.payload.selectedProduct;
      state.consumer = action.payload.consumer;
      state.tempProduct = action.payload.tempProduct;
      state.tempMerchants = action.payload.tempMerchants;
      state.filterObject = action.payload.filterObject;
    },
    unsetData: (state, action) => {
      state.data = [];
      state.totalUsers = 0;
    },
    setTotalUser: (state, action) => {
      state.totalUsers = action.payload;
    },
    setSelectedProduct: (state, action) => {
      if (state.products[action.payload]) {
        state.products[action.payload].merchantList = sortObjectKeys(
          state.products[action.payload].merchantList,
          "accending",
        );
      }
      state.selectedProduct = action.payload;
    },
    filterByMinValue: (state, action) => {
      // let value = action.payload;
      if (isNaN(action.payload)) {
        state.filterObject.min = action.payload;
      } else {
        state.filterObject.min = Number(action.payload);
      }
    },
    filterByMaxValue: (state, action) => {
      // let value = action.payload;
      if (isNaN(action.payload)) {
        state.filterObject.max = action.payload;
      } else {
        state.filterObject.max = Number(action.payload);
      }
    },
    removeMinMaxValue: (state, action) => {
      state.filterObject.max = "e";
      state.filterObject.min = "e";
    },
    addZoneFilter: (state, action) => {
      if (state.filterObject.zone?.includes(action.payload)) {
        state.filterObject.zone = state.filterObject.zone?.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.filterObject.zone = [...state.filterObject.zone, action.payload];
      }
    },
    addZoneFilterStrict: (state, action) => {
      if (state.filterObject.zone?.includes(action.payload)) {
      } else {
        state.filterObject.zone = [...state.filterObject.zone, action.payload];
      }
    },
    removeZoneFilter: (state, action) => {
      state.filterObject.zone = state.filterObject.zone?.filter(
        (item) => item !== action.payload,
      );
    },
    addPersonaFilter: (state, action) => {
      state.filterObject.persona = [
        ...state.filterObject.persona,
        action.payload,
      ];
    },
    addPersonaListFilter: (state, action) => {
      state.filterObject.persona = [...action.payload];
    },
    addZoneListFilter: (state, action) => {
      state.filterObject.zone = [...action.payload];
    },
    removePersonaFilter: (state, action) => {
      state.filterObject.persona = state.filterObject.persona?.filter(
        (item) => item !== action.payload,
      );
    },
    addProductFilter: (state, action) => {
      state.filterObject.products = {
        ...state.filterObject.products,
        [action.payload]: Object.keys(
          state?.products[action.payload]?.merchantList,
        ),
      };
    },
    removeProductFilter: (state, action) => {
      delete state.filterObject.products[action.payload];
    },
    addConsumerFilter: (state, action) => {
      if (state.filterObject.consumer.includes(action.payload)) {
        state.filterObject.consumer = state.filterObject.consumer?.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.filterObject.consumer = [
          ...state.filterObject.consumer,
          action.payload,
        ];
      }
    },
    removeConsumerFilter: (state, action) => {
      state.filterObject.consumer = state.filterObject.consumer?.filter(
        (item) => item !== action.payload,
      );
    },
    addProviderFilter: (state, action) => {
      if (state.filterObject.products[state.selectedProduct]) {
        state.filterObject.products[state.selectedProduct] = [
          ...state.filterObject.products[state.selectedProduct],
          action.payload,
        ];
      } else {
        state.filterObject.products = {
          ...state.filterObject.products,
          [state.selectedProduct]: [action.payload],
        };
      }
    },
    removeProviderFilter: (state, action) => {
      state.filterObject.products[action.product ?? state.selectedProduct] =
        state.filterObject.products[state.selectedProduct].filter(
          (item) => item !== action.payload,
        );
      // let data =
      //   state.filterObject.products[state.selectedProduct]?.length === 0
      //     ? delete state.filterObject.products[state.selectedProduct]
      //     : "";
    },
    removeProviderFilterFromProduct: (state, action) => {
      state.filterObject.products[action.payload?.product] =
        state.filterObject.products[action.payload?.product].filter(
          (item) => item !== action.payload?.provider,
        );
      // let data =
      //   state.filterObject.products[action.payload?.product]?.length === 0
      //     ? delete state.filterObject.products[action.payload?.product]
      //     : "";
    },
    setSearchText: (state, action) => {
      state.filterObject.textSearch = action.payload;
    },
    clearAllFilter: (state, action) => {
      state.filterObject = {
        textSearch: "",
        zone: [],
        persona: [],
        products: {},
        min: "e",
        max: "e",
        consumer: [],
        merchants: [],
      };

      // let data = Object.keys(state.products)?.map((item) =>
      //   state?.products[item]?.total === 0 ? delete state?.products[item] : "",
      // );
      //  let data1 = Object.keys(state.filterObject.products)?.map((item)=>state?.filterObject.products[item]?.total==0?delete state?.filterObject.products[item]:"" )
      // let temp1 = Object.keys(state.persona)?.filter((item) =>
      //   state.persona[item] === 0 ? delete state?.persona[item] : "",
      // );
      state.products = state.tempProduct;
      state.merchants = state.tempMerchants;
    },
    clearPersonaFilter: (state, action) => {
      state.filterObject.persona = [];
    },
    clearConsumerFilter: (state, action) => {
      state.filterObject.consumer = [];
    },
    resetRedux: (state, action) => {
      state = initialState;
    },
    clearProviderFilter: (state, action) => {
      if (state.selectedProduct) {
        state.products[state.selectedProduct].merchantList = sortObjectKeys(
          state.products[state.selectedProduct].merchantList,
          "accending",
        );
        state.filterObject.products[state.selectedProduct] = [];
        // state.selectedProduct = state.selectedProduct;
        delete state.filterObject.products[state.selectedProduct];
      }
    },
    clearProductFilter: (state, action) => {
      state.products = sortObjectKeys(state.products, "accending");
      state.filterObject.products = [];
    },
    clearZoneFilter: (state, action) => {
      state.filterObject.zone = [];
    },
    addTempPersona: (state, action) => {
      state.persona = {
        ...state.persona,
        [action.payload]: { total: 0, zone: [] },
      };
      state.filterObject.persona = [
        ...state.filSterObject.persona,
        action.payload,
      ];
    },
    addTempMerchants: (state, action) => {
      state.merchants = {
        ...state.merchants,
        [action.payload]: { total: 0, zone: [] },
      };
      state.filterObject.merchants = [
        ...state.filterObject.merchants,
        action.payload,
      ];
    },
    addTempProduct: (state, action) => {
      Object.keys(action.payload)?.map((productname) => {
        if (Object.keys(state.products).includes(productname)) {
          action.payload[productname]?.map((provider) => {
            if (
              Object.keys(state.products[productname].merchantList).includes(
                provider,
              )
            ) {
              state.filterObject.products[productname] = state.filterObject
                .products[productname]
                ? [provider, ...state.filterObject.products[productname]]
                : [provider];
            } else {
              state.products[productname].merchantList = {
                [provider]: { total: 0, zone: [] },
                ...state.products[productname].merchantList,
              };
              state.filterObject.products[productname] = state.filterObject
                .products[productname]
                ? [provider, ...state.filterObject.products[productname]]
                : [provider];
            }
            return null;
          });
        } else {
          state.products = {
            [productname]: {
              total: 0,
              merchantList: {},
              color: "",
              zone: [],
              personas: {},
            },
            ...state.products,
          };
          action.payload[productname]?.map(
            (item) =>
              (state.products[productname].merchantList = {
                [item]: { total: 0, zone: [], personas: {} },
                ...state.products[productname].merchantList,
              }),
          );
          state.filterObject.products[productname] =
            action.payload[productname];
        }
        return null;
      });
    },
    setTempProduct: (state, action) => {
      state.tempProduct = state.products;
    },
    setTempMerchants: (state, action) => {
      state.tempMerchants = state.merchants;
    },
    addTempProvider: (state, action) => {
      // if(Object.keys((state.products)).includes(action.payload.product))
      // {
      //       action.payload.provider?.map((item)=>{
      //         if((Object.keys(state.products[action.payload.product].merchantList)).includes(item))
      //         {
      //           state.filterObject.products[action?.payload?.product] =state.filterObject.products[ state.selectedProduct]? [... state.filterObject.products[ state.selectedProduct],item]:[item]
      //         }
      //         else{
      //               state.products[action.payload.product].merchantList =
      //             {[item]:{total:0,zone:[]},...state.products[action.payload.product]?.merchantList}
      //              state.filterObject.products[action?.payload?.product] =state.filterObject.products[ state.selectedProduct]? [... state.filterObject.products[ state.selectedProduct],item]:[item]
      //         }
      //       })
      // }
      // else{
      //   state.products = {...state.products,[action.payload.product]:{total:0,temp:true, merchantList:[],color:"",zone:[]}}
      //   action.payload.provider?.map((item)=>
      //   state.filterObject.products[action.payload.product].merchantList =
      //   {...state.filterObject.products[action.payload.product]?.merchantList,
      //    [item]:{total:0,zone:[]}})
      // }
    },
    removeTempData: (state, action) => {
      // let temp1 = Object.keys(state.persona)?.filter((item) =>
      //   state.persona[item] === 0 ? delete state?.persona[item] : "",
      // );
      state.products = state.tempProduct;
      state.merchants = state.tempMerchants;
    },
    sortAscendingByProductName: (state, action) => {
      state.products = sortObjectKeys(state.products, "accending");
    },
    sortDescendingByProductName: (state, action) => {
      state.products = sortObjectKeys(state.products, "descending");
    },
    sortAscendingByProductTotal: (state, action) => {
      state.products = sortObjectTotal(state.products, "accending");
    },
    sortDescendingByProductTotal: (state, action) => {
      state.products = sortObjectTotal(state.products, "descending");
    },
    sortAscendingByProviderName: (state, action) => {
      state.products[state?.selectedProduct].merchantList = sortObjectKeys(
        state.products[state?.selectedProduct].merchantList,
        "accending",
      );
    },
    sortDescendingByProviderName: (state, action) => {
      // state.products = sortObjectKeys(state.products,"descending")
      state.products[state?.selectedProduct].merchantList = sortObjectKeys(
        state.products[state?.selectedProduct].merchantList,
        "descending",
      );
    },
    sortAscendingByProviderTotal: (state, action) => {
      // state.products = sortObjectTotal(state.products,"accending")
      let data = sortObjectTotal(
        state.products[state?.selectedProduct].merchantList,
        "accending",
      );
      state.products = {
        ...state.products,
        [state.selectedProduct]: {
          ...state.products[state.selectedProduct],
          merchantList: data,
        },
      };
    },
    sortDescendingByProviderTotal: (state, action) => {
      // state.products = sortObjectTotal(state.products,"descending")
      state.products[state?.selectedProduct].merchantList = sortObjectTotal(
        state.products[state?.selectedProduct].merchantList,
        "descending",
      );
    },

    sortAscendingByConsumerName: (state, action) => {
      state.consumer = sortObjectKeys(state.consumer, "accending");
    },
    sortDescendingByConsumerName: (state, action) => {
      state.consumer = sortObjectKeys(state.consumer, "descending");
    },
    sortAscendingByConsumerTotal: (state, action) => {
      state.consumer = sortObjectTotal(state.consumer, "accending");
    },
    sortDescendingByConsumerTotal: (state, action) => {
      state.consumer = sortObjectTotal(state.consumer, "descending");
    },

    addMerchantsFilter: (state, action) => {
      if (!(state?.filterObject?.merchants ?? []).includes(action.payload)) {
        state.filterObject.merchants = [
          ...state.filterObject.merchants,
          action.payload,
        ];
      } else {
        state.filterObject.merchants = state.filterObject.merchants.filter(
          (item) => {
            return item !== action.payload;
          },
        );
      }
    },
    clearMerchantsFilter: (state, action) => {
      state.filterObject.merchants = [];
      state.merchants = sortObjectKeys(state.merchants, "accending");
    },
    sortAscendingByMerchantName: (state, action) => {
      state.merchants = sortObjectKeys(state.merchants, "accending");
    },
    sortDescendingByMerchantName: (state, action) => {
      state.merchants = sortObjectKeys(state.merchants, "descending");
    },
    sortAscendingByMerchantTotal: (state, action) => {
      state.merchants = sortObjectTotal(state.merchants, "accending");
    },
    sortDescendingByMerchantTotal: (state, action) => {
      state.merchants = sortObjectTotal(state.merchants, "descending");
    },
    addSecondaryConsumerFilter: (state, action) => {
      if (state.filterObject.consumer.includes(action.payload)) {
      } else {
        state.filterObject.consumer = [
          ...state.filterObject.consumer,
          action.payload,
        ];
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetRedux,
  addSecondaryConsumerFilter,
  addZoneFilterStrict,
  addZoneListFilter,
  addPersonaListFilter,
  setTotalUser,
  removeProviderFilterFromProduct,
  setSearchText,
  addConsumerFilter,
  clearAllFilter,
  clearZoneFilter,
  clearMinMaxFilter,
  clearConsumerFilter,
  clearPersonaFilter,
  clearProductFilter,
  clearProviderFilter,
  addTempPersona,
  addTempProduct,
  removeTempData,
  addTempProvider,
  unsetData,
  removeMinMaxValue,
  sortAscendingByProductName,
  sortAscendingByProductTotal,
  sortDescendingByProductName,
  sortDescendingByProductTotal,
  removeConsumerFilter,
  setTempMerchants,
  setTempProduct,
  addProviderFilter,
  removeProviderFilter,
  addPersonaFilter,
  addProductFilter,
  removeProductFilter,
  sortAscendingByProviderName,
  sortAscendingByProviderTotal,
  sortDescendingByProviderName,
  sortDescendingByProviderTotal,
  removePersonaFilter,
  setData,
  addTempMerchants,
  setSelectedProduct,
  filterByMinValue,
  filterByMaxValue,
  addZoneFilter,
  removeZoneFilter,
  sortAscendingByConsumerName,
  setStateDirect,
  sortAscendingByConsumerTotal,
  sortDescendingByConsumerName,
  sortDescendingByConsumerTotal,
  addMerchantsFilter,
  sortAscendingByMerchantName,
  sortDescendingByMerchantName,
  clearMerchantsFilter,
  sortAscendingByMerchantTotal,
  sortDescendingByMerchantTotal,
} = filterOptionSlice.actions;

export default filterOptionSlice.reducer;
