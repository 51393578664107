import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  balance: {
    min: null,
    max: null,
  },
  product: [],
  persona: [],
  zone: [],
  provider: [],
  productWithProvider: [],
  currentProduct: "",
  consumer: ["secondary_consumers"],
};
const selectedFilterSlice = createSlice({
  name: "selectedFilterSlice",
  initialState,
  reducers: {
    setFilterProducts(state, action) {
      state.product = action.payload;
    },
    setFilterProviders(state, action) {
      state.provider = action.payload;
    },
    setFilterproductWithProvider(state, action) {
      state.productWithProvider = action.payload;
    },
    setFilterZones(state, action) {
      state.zone = action.payload;
    },
    setFilterPersonas(state, action) {
      state.persona = action.payload;
    },
    setFilterConsumers(state, action) {
      state.consumer = action.payload;
    },
    setFilterBalance(state, action) {
      state.balance.min = action.payload.min;
      state.balance.max = action.payload.max;
    },
    setCurrentProduct(state, action) {
      state.currentProduct = action.payload;
    },
    resetFilters(state, action) {
      state.product = [];
      state.provider = [];
      state.zone = [];
      state.persona = [];
      state.currentProduct = "";
      state.productWithProvider = [];
      state.consumer = ["secondary_consumers"];
      state.balance.min = null;
      state.balance.max = null;
    },
  },
});

export const {
  setFilterProducts,
  setFilterproductWithProvider,
  setFilterProviders,
  setFilterZones,
  setFilterPersonas,
  setCurrentProduct,
  setFilterBalance,
  setFilterConsumers,
  resetFilters,
} = selectedFilterSlice.actions;
export default selectedFilterSlice.reducer;
