import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  dataLoading: "",
  logoutLoading: false,
  performaLoading: false,
};

export const loaderSlice = createSlice({
  name: "loaderSlice",
  initialState,
  reducers: {
    setLoaderTrue: (state) => {
      state.loader = true;
    },
    setLoaderFalse: (state) => {
      state.loader = false;
    },
    setDataLoading: (state, action) => {
      state.dataLoading = action.payload;
    },
    setLogoutLoading: (state, action) => {
      state.logoutLoading = action.payload;
    },
    setPerformaLoading: (state, action) => {
      state.performaLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoaderTrue,
  setLoaderFalse,
  setDataLoading,
  setLogoutLoading,
  setPerformaLoading,
} = loaderSlice.actions;

export default loaderSlice.reducer;
