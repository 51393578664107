import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { toastResponse } from "../helper components/Toast/Toast";
import { setStateDirect } from "./filterOptions";

const initialState = {
  data: [],
  totalUsers: 0,
  status: false,
  hid: "",
  dataRecived: false,
};
let worker = new Worker("/worker.js");

export const GetMissingProductDrilldownData = createAsyncThunk(
  "/bank/healthanalysis/missingproduct",
  async (healthanalysis, thunkAPI) => {
    try {
      const url = process.env.REACT_APP_API_URL;
      let currentState = thunkAPI.getState();
      let tempState = {
        ...currentState,
        authReducer: {
          ...currentState.authReducer,
          sessionid: currentState.authReducer?.userData?.sessionid + 131,
        },
      };
      // tempState.authReducer.userData.sessionid = tempState.authReducer?.userData?.sessionid+131
      currentState = tempState;
      worker.postMessage({
        action: "fetchdata",
        healthanalysis: { ...healthanalysis, type: "MissingProduct" },
        currentState,
        url,
        type: "Missing_Product",
      });
      worker.onmessage = (event) => {
        const { action, data } = event.data;
        if (action === "updateStore") {
          // Dispatch an action to update the Redux store
          thunkAPI.dispatch(setMissingProductStatus(data.status));
          thunkAPI.dispatch(setMissingProductTotalUser(data.totalUsers));
        } else if (action === "dataUpdate") {
        } else if (action === "result") {
          // Handle the final result received from the worker if needed
          thunkAPI.dispatch(setMissingProductTotalUser(data.totalUsers));
          thunkAPI.dispatch(setMissingProductData(data.data));
          thunkAPI.dispatch(setMissingProductStatus(data.status));
        } else if (action === "error") {
          thunkAPI.dispatch(setMissingProductStatus(false));
        }
      };
    } catch (error) {
      console.log(error);
    }
  },
);
export const GetMissingProductDrilldownDataInRedux = createAsyncThunk(
  "/bank/healthanalysis/missingproduct/slice",
  async (data, thunkAPI) => {
    worker.postMessage({ action: "getopportunitiesdata" });
    worker.onmessage = (event) => {
      const { action, data } = event.data;
      if (action === "finalresult") {
        thunkAPI.dispatch(setMissingProductStatus(data.status));
        thunkAPI.dispatch(setStateDirect(data.state));
      }
    };
  },
);
export const missingproductSlice = createSlice({
  name: "missingproductSlice",
  initialState,
  reducers: {
    resetMissingProductData: (state, action) => {
      // worker.terminate()
      state = initialState;
      worker.terminate(); // Terminate the existing worker
      worker = new Worker("worker.js");
    },
    setMissingProductData: (state, action) => {
      state.data = action.payload;
    },
    setMissingProductTotalUser: (state, action) => {
      state.totalUsers = action.payload;
    },
    setMissingProductStatus: (state, action) => {
      state.status = action.payload;
    },
    setMissingProductHid: (state, action) => {
      state.hid = action.payload;
    },
    getMissingProductAllData: (state, action) => {},
  },
  extraReducers: {
    [GetMissingProductDrilldownData.pending]: (state, action) => {},
    [GetMissingProductDrilldownData.fulfilled]: (state, action) => {},
    [GetMissingProductDrilldownData.rejected]: (state, action) => {
      toast.error(toastResponse("error", action.error.message));
    },
  },
});

// export const { preSign} = preSign.actions
export const {
  resetData,
  setMissingProductData,
  getMissingProductAllData,
  resetMissingProductData,
  setMissingProductHid,
  setMissingProductStatus,
  setMissingProductTotalUser,
} = missingproductSlice.actions;
export default missingproductSlice.reducer;
