import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const trendsAnalysisSlice = createApi({
  reducerPath: "analysis_trends",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: [
    "OpportunitiesTrend",
    "MissingProductsTrend",
    "GhostConsumersTrend",
    "SecondaryConsumersTrend",
    "BondScoreTrend",
    "MissingRevenueTrend",
  ],
  endpoints: (build) => ({
    getOpportunitiesTrend: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=opportunities&view=trend&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
      providesTags: (_) => ["OpportunitiesTrend"],
    }),

    getMissingProductsTrend: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=missing_product&view=trend&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
      providesTags: (_) => ["MissingProductsTrend"],
    }),

    getGhostConsumersTrend: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=ghost_consumer&view=trend&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
      providesTags: (_) => ["GhostConsumersTrend"],
    }),

    getSecondaryConsumersTrend: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=primary_consumer&view=trend&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
      providesTags: (_) => ["SecondaryConsumersTrend"],
    }),

    getBondScoreTrend: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=bond_score&view=trend&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
      providesTags: (_) => ["BondScoreTrend"],
    }),

    getMissingRevenueTrend: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=missed_revenue&view=trend&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
      providesTags: (_) => ["MissingRevenueTrend"],
    }),
  }),
});

export const {
  useGetOpportunitiesTrendQuery,
  useGetMissingProductsTrendQuery,
  useGetGhostConsumersTrendQuery,
  useGetSecondaryConsumersTrendQuery,
  useGetBondScoreTrendQuery,
  useGetMissingRevenueTrendQuery,
} = trendsAnalysisSlice;
