import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const missedRevenueAnalysisSlice = createApi({
  reducerPath: "analysis_missed_revenue",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["missed_revenue"],
  endpoints: (build) => ({
    getMissedRevenueSummary: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=missed_revenue&view=summary&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),

    getMissedRevenueExpended: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=missed_revenue&view=expand&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetMissedRevenueSummaryQuery,
  useGetMissedRevenueExpendedQuery,
} = missedRevenueAnalysisSlice;
