import "./ListOptions.scss";
const ListOptions = ({ options, onClick }) => {
  return (
    <ul className="list-options">
      {options.map((item, index) => {
        return (
          <li key={index} onClick={(e) => onClick(e, item)}>
            {item.icon && (
              <img src={item.icon} className="peyeicon" alt={item.label} />
            )}

            <div>{item.label}</div>
          </li>
        );
      })}
    </ul>
  );
};

export default ListOptions;
