import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const registrationSlice = createApi({
  reducerPath: "registration",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["registration"],
  keepUnusedDataFor: 5,
  endpoints: (build) => ({
    getInstitution: build.query({
      query: ({ filter, pageNumber, pagesize }) => {
        return {
          url: `/organization/bank?filter=${filter}&pageNumber=${pageNumber}&pagesize=${pagesize}`,
        };
      },
      transformResponse: (response) => {
        if (response.status === "SUCCESS") {
          let res = [];
          let result = response.data;
          //     result = result.sort((a, b) =>
          //       a.displayName.toLowerCase() > b.displayName.toLowerCase() ? 1 : -1,
          //     );
          for (let i of result) {
            res.push({
              ...i,
              name: i.displayName,
              value: i.orgKey,
              ParentOrgName: i.parent,
            });
          }
          return { ...response, data: res };
        } else {
          return { ...response, data: null };
        }
      },
    }),
    getDepartment: build.query({
      query: () => ({
        url: `/organization/department`,
      }),
      transformResponse: (response) => {
        if (response.status === "SUCCESS") {
          let res = [];
          let result = response.data;
          // result = result.sort((a, b) =>
          //   a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          // );
          for (let i of result) {
            res.push({
              ...i,
              value: i.id,
            });
          }
          return { ...response, data: res };
        } else {
          return { ...response, data: null };
        }
      },
    }),
    getLevel: build.query({
      query: () => ({
        url: `/organization/levels`,
      }),
      transformResponse: (response) => {
        if (response.status === "SUCCESS") {
          let res = [];
          let result = response.data;
          // result = result.sort((a, b) =>
          //   a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          // );
          for (let i of result) {
            res.push({
              ...i,
              value: i.id,
            });
          }
          return { ...response, data: res };
        } else {
          return { ...response, data: null };
        }
      },
    }),
  }),
});

export const {
  useGetDepartmentQuery,
  useGetLevelQuery,
  useLazyGetInstitutionQuery,
} = registrationSlice;
