import "./App.scss";
import chmln from "@chamaeleonidae/chmln";
import Highcharts from "highcharts";
import { NavigatingRoutes } from "./routes/Routes";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.css";
import { useChameleonModalContext } from "./Context/ChameleonModal";
import { useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector } from "react-redux";
import useExitPrompt, { ExitContext } from "./utils/useExitPrompt";
import { deleteAllCookies } from "./utils/Utils";

require("highcharts/modules/accessibility")(Highcharts);
function App() {
  const chameleonContext = useChameleonModalContext();
  const authReducer = useSelector((state) => state.authReducer);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  // HARD REFRESH - START 
  // function urlWithRndQueryParam(url, paramName) {
  //   const ulrArr = url.split("#");
  //   const urlQry = ulrArr[0].split("?");
  //   const usp = new URLSearchParams(urlQry[1] || "");
  //   usp.set(paramName || "_z", `${Date.now()}`);
  //   urlQry[1] = usp.toString();
  //   ulrArr[0] = urlQry.join("?");
  //   return ulrArr.join("#");
  // }

  // async function handleHardReload(url) {
  //   const newUrl = urlWithRndQueryParam(url);
  //   await fetch(newUrl, {
  //     headers: {
  //       Pragma: "no-cache",
  //       Expires: "-1",
  //       "Cache-Control": "no-cache",
  //     },
  //   });
  //   window.location.href = url;
  //   // This is to ensure reload with url's having '#'
  //   window.location.reload(true);
  // }

  // if (!sessionStorage.getItem("isHardReload")) {
  //   sessionStorage.setItem("isHardReload", "true");
  //   handleHardReload(window.location.href);
  // }
  // HARD REFRESH - END 

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-HRN7TTHQ9N");
    }

    return deleteAllCookies();
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        chameleonContext.setChameleonValue(false);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("keydown", handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authReducer?.isLogin) {
      const chmlnFlag = process.env.REACT_APP_CHAMELEON_IS_ENABLE;

      // console.log(process.env.REACT_APP_CHAMELEON_IS_ENABLE);
      if (chmlnFlag === "true") {
        //initialize chameleon
        chmln.init(process.env.REACT_APP_CHAMELEON_API_Key);
        localStorage.setItem("chameleonInitialized", "true");
        //send user data to chameleon
        chmln.identify(
          `${authReducer.userData.orguid}${authReducer.userData.useruid}`,
          {
            // REQUIRED, the unique ID of each user in your database (e.g. 23443 or "690b80e5f433ea81b96c9bf6")/* chameleon.io user identification and data */
            email: authReducer.userData.userName,
            role: authReducer.userData?.role, // RECOMMENDED, email is used as the key to map user data for integrations
          },
        );
      }
    }
  }, [authReducer]);

  return (
    <>
      <ExitContext.Provider value={{ showExitPrompt, setShowExitPrompt }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <NavigatingRoutes />
        </LocalizationProvider>
      </ExitContext.Provider>
    </>
  );
}

export default App;
