import "./WithLocking.scss";

export const cardUpgradeData = [
  {
    src: "missing_products",
    name: "missing_products",
    title: "Unlock Missing Product Insights",
    description:
      "Upgrade and unveil the Missing Products Card, revealing untapped market opportunities. Analyze consumer counts and percentages, strategize, and expand your product offerings. <div style='margin-top:1%'>Upgrade now to stay ahead!</div>",
  },
  {
    src: "opportunities",
    name: "opportunities",
    title: "Upgrade for Exclusive Growth Insights!",
    description:
      "Ready to take your business to new heights? Upgrade to our Pro version now to unlock the full potential of our Opportunity Card feature! Gain access to valuable insights on both existing and potential consumers, empowering you to make data-driven decisions that drive growth. <div style='font-weight:500;color:black;margin-top:3.5%'>Don't miss out on this opportunity to level up your strategy and maximize your business's potential.</div>",
  },
  {
    src: "ghost_consumers",
    name: "ghost_consumers",
    title: "Spot Ghost Consumers",
    description:
      "Upgrade to reveal the Ghost Consumers Card, shedding light on those favouring competitors' products over ours. Gain insights into counts and make informed decisions to win them back. <div style='margin-top:1%'>Upgrade now for proactive strategy building.</div>",
  },
  {
    src: "secondary_consumers",
    name: "secondary_consumers",
    title: "Transform Your Approach to Secondary Consumers",
    description:
      "<div>Unlock valuable insights into your secondary consumers - those favoring products from other financial institutions over yours. Upgrade today to access crucial information that could transform your approach and elevate your offerings, ensuring you stay ahead in the competitive landscape. <div style='font-weight:500;color:black'>Upgrade now to unlock exclusive insights and gain the competitive edge.</div></div>",
  },
  {
    src: "top_competitors",
    name: "top_competitors",
    title: "Know Your Top Competitors",
    description:
      "Upgrade to access the Top Competitor Card, unveiling the financial competitors preferred by your customers over our products. Gain valuable insights to refine your strategy and win over your audience. Upgrade now to stay ahead of the competition.",
  },
  {
    src: "top_merchants",
    name: "top_merchants",
    title: "Discover Preferred Merchants",
    description:
      "Unlock the Top Merchants Card with upgrade, revealing the non-financial merchants your customers frequent the most. Gain invaluable insights to tailor your offerings and enhance customer experience. <div style='margin-top:1%'>Upgrade now to stay connected with your audience's preferences.</div>",
  },
  {
    src: "growth",
    name: "growth",
    title: "Track New Consumer Growth",
    description:
      "Introducing the New Growth Card: easily monitor how many new consumers have joined your bank. Stay informed, strategize effectively, and capitalize on opportunities for expansion. <div style='margin-top:1%'>Upgrade now to stay ahead of the curve.</div>",
  },
  {
    src: "efficiency_ratio",
    name: "efficiency_ratio",
    title: "Optimize Your Efficiency Ratio",
    description:
      "Upgrade now to access the Efficiency Ratio feature, revealing how much your financial institution is spending to make money. Identify areas for improvement and maximize profitability. Upgrade today and unlock your institution's full potential!",
  },
  {
    src: "top_personas",
    name: "top_personas",
    title: "Explore Consumer Personas for Deeper Insights!",
    description:
      "Discover Your Audience's Personas! Upgrade to reveal the Top Personas card, providing valuable insights into the diverse persona types of your consumers. Enhance your targeting and engagement strategies for maximum impact.",
  },
  {
    src: "missed_revenue",
    name: "missed_revenue",
    title: "Recover Missed Revenue",
    description: `<p>Unlock the Missed Revenue Card to discover,</p>
      <ol class="bullet-list">
      <li>Revenue leaking to other institutions</li>
      <li>Amount your consumers spend on products from other institutions</li>
      <li>New product opportunities</li>
  </ol>
      `,
  },
  {
    src: "bond_score",
    name: "bond_score",
    title: "Unlock BOND Score Insights",
    description:
      "Introducing the BOND Score: Gain insights into the average financial well-being rating of your consumers based on their transaction patterns. Better understand their financial health to tailor your offerings effectively. Upgrade now and prioritize your consumers' financial wellness!",
  },
  {
    src: "consumer_demographics",
    name: "consumer_demographics",
    title: "Explore Consumer Demographics",
    description:
      "Delve into the intricacies of consumer demographics with our upgrade. Gain deeper insights into your audience's characteristics and preferences. <div style='margin-top:1%'>Upgrade now to tailor your strategies and enhance engagement effectively.</div>",
  },
  {
    src: "popup_icon_upgrade_campaign",
    name: "upgrade_email_campaign",
    title: "Upgrade to Marketing Mastery",
    description:
      "Unlock the power of marketing mastery with our Pro upgrade. Gain access to advanced tools and insights, empowering you to elevate your marketing strategies and drive unparalleled results. Upgrade now and unleash your brand's full potential.",
  },
  {
    src: "financial_health_score",
    name: "financial_health_score",
    title: "Unleash the power of data exclusivity with our membership!",
    description:
      "Upgrade for exclusive access to comprehensive banking insights, beyond the FHS score. Unleash precision in targeting users, revolutionize your data-driven strategies, and stay steps ahead in the competitive landscape.",
  },
  {
    src: "contact_sales_team",
    name: "first_time_logged_in",
    title: "Welcome to Enhanced Insights on Your Dashboard!",
    description: `Welcome aboard! We're thrilled to have you here. Upgrade now to unlock a world of enhanced opportunities with our Pro version. Gain access to exclusive features, advanced tools, and invaluable insights that will take your experience to the next level. 
                  <div class="text-dark mt-3">Don't miss out – upgrade today and embark on a journey of unparalleled growth and success!</strong>`,
  },
  {
    src: "contact_sales_team",
    name: "premium_service",
    title: "Autopilot your bank's growth",
    description: ` We can’t wait to see your institution grow with Autopilot from BOND.AI. Our team will be in touch with you very soon. 
                 `,
    //  <div class="mt-3">They will be in touch shortly to assist you further.
    //  </div>
  },
  {
    src: "uplift_loans",
    name: "uplift_loans",
    title: "Personalized loans anticipating future needs",
    description: `<p>Find new opportunities for,</p>
      <ol class="bullet-list">
      <li>Immediate loans.</li>
      <li>Future loans based on predicted consumer needs.</li>
      <li>Filter loan opportunities based on financial health, persona of the consumer.</li>
      <li>Mortgages, auto loans, business loans and beyond.</li>
  </ol>
      `,
  },
  {
    src: "boost_deposits",
    name: "boost_deposits",
    title: "Optimize deposits, predict growth, beat competition",
    description: `<p>Uncovers the following,</p>
      <ol class="bullet-list">
      <li>Optimal deposit opportunities and instantly shows growth potential.</li>
      <li>See deposit attrition and prevent it.</li>
      <li>Identify loan-only clients to convert into deposit clients.</li>
      <li>Filter deposit opportunities based on financial health, loyalty of the consumer.</li>
  </ol>
      `,
  },
  {
    src: "beat_the_competition",
    name: "beat_the_competition",
    title: "Compete with data",
    description: `<p>Get the edge over the other guys. Discover,</p>
      <ol class="bullet-list">
      <li>Your top competitors</li>
      <li>Your consumers other financial associations. </li>
      <li>Champion your local community with dynamic insights for both retail and small-business accounts.</li>
  </ol>
      `,
  },
  {
    src: "automated_marketing",
    name: "automated_marketing",
    title: "AI-Powered Email Campaigning",
    description: `<p>Get AI-powered insights elevate your campaign.</p>
      <ol class="bullet-list">
      <li>Automatically create lists that identify client needs early for most impact.</li>
      <li>Consciously designed pre-built templates. </li>
      <li>Prebuilt content (coming soon)</li>
      <li>Launch full campaigns with tracking </li>
  </ol>
      `,
  },
  {
    src: "consumer_behavior",
    name: "consumer_behavior",
    title: "Move beyond knowing to understanding your consumer",
    description: `<p>Unlocking in-depth consumer behavior insights like,</p>
      <ol class="bullet-list">
      <li>Individual financial health score</li>
      <li>Personas 2.0</li>
      <li>Ghost consumers and loyalty</li>
      <li>Primary vs secondary consumers</li>
      <li>Details Demographics</li>
  </ol>`,
  },
  // Add more cards as needed
];
