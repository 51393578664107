import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const missingProductsAnalysisSlice = createApi({
  reducerPath: "analysis_missing_product",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["missing_product"],
  endpoints: (build) => ({
    getMissingProductsSummary: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=missing_product&view=summary&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),

    getMissingProductsExpended: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=missing_product&view=expand&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetMissingProductsSummaryQuery,
  useGetMissingProductsExpendedQuery,
} = missingProductsAnalysisSlice;
