import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { toastResponse } from "../helper components/Toast/Toast";
import { setStateDirect } from "./filterOptions";
import { GetSecondaryConsumerDrilldownData } from "./secondaryConsumer";
// import { setOrgId } from '../../../Redux/orgDetailsSlice'
// import { useDispatch } from 'react-redux';
//import { store } from '../../../app/storbhaRequestUide';

const initialState = {
  data: [],
  totalUsers: 0,
  status: false,
  hid: "",
  dataRecived: false,
};
let worker = new Worker("/worker.js");

export const GetOpportunitiesDrilldownData = createAsyncThunk(
  "/bank/healthanalysis/opportunities",
  async (healthanalysis, thunkAPI) => {
    try {
      const url = process.env.REACT_APP_API_URL;
      const currentState = thunkAPI.getState();
      worker.postMessage({
        action: "fetchdata",
        healthanalysis: { ...healthanalysis, type: "CrossSellOpp" },
        currentState,
        url,
      });
      worker.onmessage = (event) => {
        const { action, data } = event.data;
        if (action === "updateStore") {
          // Dispatch an action to update the Redux store
          thunkAPI.dispatch(setOpportunitiesStatus(data.status));
          thunkAPI.dispatch(setOpportunitiesTotalUser(data.totalUsers));
        } else if (action === "dataUpdate") {
          thunkAPI.dispatch(
            GetSecondaryConsumerDrilldownData({
              hid: healthanalysis.hid,
              flag: "initiated",
            }),
          );
        } else if (action === "result") {
          // Handle the final result received from the worker if needed
          thunkAPI.dispatch(setOpportunitiesTotalUser(data.totalUsers));
          thunkAPI.dispatch(setOpportunitiesData(data.data));
          thunkAPI.dispatch(setOpportunitiesStatus(data.status));
        } else if (action === "error") {
          thunkAPI.dispatch(setOpportunitiesStatus(false));
        }
      };
    } catch (error) {
      console.log(error);
    }
  },
);
export const GetOpportunitiesDrilldownDataInRedux = createAsyncThunk(
  "/bank/healthanalysis/opportunities/slice",
  async (data, thunkAPI) => {
    worker.postMessage({ action: "getopportunitiesdata" });
    worker.onmessage = (event) => {
      const { action, data } = event.data;
      if (action === "finalresult") {
        thunkAPI.dispatch(setOpportunitiesStatus(data.status));
        thunkAPI.dispatch(setStateDirect(data.state));
      }
    };
  },
);
export const opportunitiesSlice = createSlice({
  name: "opportunitiesSlice",
  initialState,
  reducers: {
    resetOpportunitiesData: (state, action) => {
      state = initialState;
      worker.terminate(); // Terminate the existing worker
      worker = new Worker("worker.js");
    },
    setOpportunitiesData: (state, action) => {
      state.data = action.payload;
    },
    setOpportunitiesTotalUser: (state, action) => {
      state.totalUsers = action.payload;
    },
    setOpportunitiesStatus: (state, action) => {
      state.status = action.payload;
    },
    setOpportunitiesHid: (state, action) => {
      state.hid = action.payload;
    },
    getOpportunitiesAllData: (state, action) => {},
  },
  extraReducers: {
    [GetOpportunitiesDrilldownData.pending]: (state, action) => {},
    [GetOpportunitiesDrilldownData.fulfilled]: (state, action) => {},
    [GetOpportunitiesDrilldownData.rejected]: (state, action) => {
      toast.error(toastResponse("error", action.error.message));
    },
  },
});

// export const { preSign} = preSign.actions
export const {
  resetData,
  setOpportunitiesData,
  getOpportunitiesAllData,
  resetOpportunitiesData,
  setOpportunitiesHid,
  setOpportunitiesStatus,
  setOpportunitiesTotalUser,
} = opportunitiesSlice.actions;
export default opportunitiesSlice.reducer;
