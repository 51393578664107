import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetFromServer } from "../utils/GetFromServer";
import { toast } from "react-toastify";
import { toastResponse } from "../helper components/Toast/Toast";
import { store } from "../app/store";
// import { setOrgId } from '../../../Redux/orgDetailsSlice'
// import { useDispatch } from 'react-redux';
//import { store } from '../../../app/storbhaRequestUide';

const initialState = {
  bhaRequestUid: localStorage.getItem("PRE_SIGN_URL")
    ? JSON.parse(localStorage.getItem("PRE_SIGN_URL"))?.bhaRequestUid
    : null,
  consumer_url: localStorage.getItem("PRE_SIGN_URL")
    ? JSON.parse(localStorage.getItem("PRE_SIGN_URL"))?.consumer_url
    : null,
  product_url: localStorage.getItem("PRE_SIGN_URL")
    ? JSON.parse(localStorage.getItem("PRE_SIGN_URL"))?.product_url
    : null,
  account_url: localStorage.getItem("PRE_SIGN_URL")
    ? JSON.parse(localStorage.getItem("PRE_SIGN_URL"))?.account_url
    : null,
  trancastion_url: localStorage.getItem("PRE_SIGN_URL")
    ? JSON.parse(localStorage.getItem("PRE_SIGN_URL"))?.trancastion_url
    : null,
  romdata_url: localStorage.getItem("PRE_SIGN_URL")
    ? JSON.parse(localStorage.getItem("PRE_SIGN_URL"))?.romdata_url
    : null,
};

export const PreSign = createAsyncThunk(
  "/bank/healthanalysis/pre_sign",

  async (thunkAPI) => {
    try {
      const orguid = store.getState().orgReducer.orguid;
      const response = await GetFromServer(
        "/bank/healthanalysis/pre_sign",
        {},
        {},
        { orguid },
      );
      localStorage.setItem("PRE_SIGN_URL", JSON.stringify(response.data.data));
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const preSignSlice = createSlice({
  name: "preSignSlice",
  initialState,
  reducers: {
    setBHAid: (state, action) => {
      state.bhaRequestUid = action.payload;
      localStorage.setItem("PRE_SIGN_URL", JSON.stringify(state));
    },
  },
  extraReducers: {
    [PreSign.pending]: (state, action) => {},
    [PreSign.fulfilled]: (state, action) => {
      state.bhaRequestUid = action.payload.data.bhaRequestUid;
      state.consumer_url = action.payload.data.consumer_url;
      state.product_url = action.payload.data.product_url;
      state.account_url = action.payload.data.account_url;
      state.trancastion_url = action.payload.data.trancastion_url;
      state.romdata_url = action.payload.data.romdata_url;
    },
    [PreSign.rejected]: (state, action) => {
      toast.error(toastResponse("error", action.error.message));
      sessionStorage.clear();
      sessionStorage.clear();
      state.userData = {};
      state.isLogin = false;
      state.token = null;
      state.bhaRequestUid = false;
      state.consumer_url = false;
      state.product_url = false;
      state.account_url = false;
      state.trancastion_url = false;
      state.romdata_url = false;
    },
  },
});

// export const { preSign} = preSign.actions
export const { preSign, setBHAid } = preSignSlice.actions;
export default preSignSlice.reducer;
