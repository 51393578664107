import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const newUserGrowthAnalysisSlice = createApi({
  reducerPath: "analysis_new_user_growth",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["new_user_growth"],
  endpoints: (build) => ({
    getNewUserGrowthSummary: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=consumer_account_growth&view=summary&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),
    getNewUserGrowthExpended: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=consumer_account_growth&view=expand&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetNewUserGrowthSummaryQuery,
  useGetNewUserGrowthExpendedQuery,
} = newUserGrowthAnalysisSlice;
