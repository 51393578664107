import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GetFromServer from "../utils/GetFromServer";
import { store } from "../app/store";
import { setLoaderFalse, setLoaderTrue } from "./loaderSlice";

const initialState = {
  drilldownCohort: [],
};

export const fhsDrilldownCohortThunk = createAsyncThunk(
  "/api/api/cohortDrilldown",

  async ({ cohort_id, cohort_type }, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoaderTrue());
      const orguid =
        store.getState().orgReducer.selectedFI.orguid !== null
          ? store.getState().orgReducer.selectedFI.orguid
          : store.getState().orgReducer.selectedFI.orgKey;
      const response = await GetFromServer(
        `bank/${orguid}/cohort/data/${cohort_id}?cohort_type=${cohort_type}`,
        {},
        {},
        {},
      );
      thunkAPI.dispatch(setLoaderFalse());
      const cohortList = response.data.data;
      return cohortList;
    } catch (error) {
      thunkAPI.dispatch(setLoaderFalse());
      console.log(error);
    }
  },
);
export const fhsDrilldownSlice = createSlice({
  name: "fhsDrilldownSlice",
  initialState,
  reducers: {
    // Add reset reducer
    resetState: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [fhsDrilldownCohortThunk.pending]: (state, action) => {},
    [fhsDrilldownCohortThunk.fulfilled]: (state, action) => {
      state.drilldownCohort = action.payload;
    },
    [fhsDrilldownCohortThunk.rejected]: (state, action) => {},
  },
});

// Export the reset action
export const { resetState } = fhsDrilldownSlice.actions;

export default fhsDrilldownSlice.reducer;
