import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PostToServer } from "../../../../utils/PostToServer";
import _ from "lodash";

const initialState = {
  isFHSReset: false,
  skipAPICall: false,
  skipFHSAPICall: false,
  showGaugeChart: false,
  financialHealthScoreSummary: null,
  financialHealthScoreExpanded: null,
};

export const fetchFinancialHealthScoreSummary = createAsyncThunk(
  `fhs/getFinancialHealthScoreSummary`,
  async ({ orgId, payload }, thunkAPI) => {
    try {
      const response = await PostToServer(
        `bank/${orgId}/fhs?card=''&view=summary&page=0&size=10`,
        payload,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.data.error);
    }
  },
);

export const fetchFinancialHealthScoreExpanded = createAsyncThunk(
  `fhs/getFinancialHealthScoreExpanded`,
  async ({ orgId, payload }, thunkAPI) => {
    try {
      const response = await PostToServer(
        `bank/${orgId}/fhs?card=''&view=expand&page=0&size=10`,
        payload,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error.data.error);
    }
  },
);

const financialHealthScoreAnalysisSlice = createSlice({
  name: "analysis_financial_health_score",
  initialState,
  reducers: {
    setIsFHSReset: (state, action) => {
      state.isFHSReset = action.payload;
    },
    setSkipAPICall: (state, action) => {
      state.skipAPICall = action.payload;
    },
    setSkipFHSAPICall: (state, action) => {
      state.skipFHSAPICall = action.payload;
    },
    resetFHSSummary: (state) => {
      state.financialHealthScoreSummary = {
        ...state.financialHealthScoreSummary,
        payload: null,
      };
    },
    resetFHSExpanded: (state) => {
      state.financialHealthScoreExpanded = {
        ...state.financialHealthScoreExpanded,
        payload: null,
      };
    },
    setShowGaugeChart: (state, action) => {
      state.showGaugeChart = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFinancialHealthScoreSummary.fulfilled, (state, action) => {
        state.financialHealthScoreSummary = {
          ...state.financialHealthScoreSummary,
          payload: action.payload,
          isLoading: false,
        };
      })
      .addCase(fetchFinancialHealthScoreSummary.pending, (state, action) => {
        state.financialHealthScoreSummary = {
          isLoading: true,
        };
      })
      .addCase(fetchFinancialHealthScoreExpanded.fulfilled, (state, action) => {
        state.financialHealthScoreExpanded = {
          ...state.financialHealthScoreExpanded,
          payload: action.payload,
          isLoading: false,
        };
      });
  },
});

export const selectFinancialHealthScoreSummary = (state) => {
  return {
    payload: _.get(
      state,
      "financialHealthScoreAnalysisReducer.financialHealthScoreSummary.payload.data[0]",
      [],
    ),
    isLoading: _.get(
      state,
      "financialHealthScoreAnalysisReducer.financialHealthScoreSummary.isLoading",
      false,
    ),
  };
};

export const selectFinancialHealthScoreExpanded = (state) => ({
  payload: _.get(
    state,
    "financialHealthScoreAnalysisReducer.financialHealthScoreExpanded.payload.data[0]",
    [],
  ),

  isLoading: _.get(
    state,
    "financialHealthScoreAnalysisReducer.financialHealthScoreExpanded.isLoading",
    false,
  ),
});

export const selectIsFHSReset = (state) =>
  state?.financialHealthScoreAnalysisReducer?.isFHSReset;

export const selectSkipAPICall = (state) =>
  state?.financialHealthScoreAnalysisReducer?.skipAPICall;

export const selectSkipFHSAPICall = (state) =>
  state?.financialHealthScoreAnalysisReducer?.skipFHSAPICall;

export const selectShowGaugeChart = (state) =>
  state?.financialHealthScoreAnalysisReducer?.showGaugeChart;

export const {
  setIsFHSReset,
  setSkipAPICall,
  setSkipFHSAPICall,
  setShowGaugeChart,
  resetFHSSummary,
  resetFHSExpanded,
} = financialHealthScoreAnalysisSlice.actions;

export default financialHealthScoreAnalysisSlice.reducer;
