import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errorType: [],
};

export const errorSlice = createSlice({
  name: "error_handling",
  initialState,
  reducers: {
    setErrorType: (state, action) => {
      state.errorType = [...state.errorType, action.payload];
    },
    resetErrorType: (state, action) => {
      state.errorType = [];
    },
  },
});

export const { setErrorType, resetErrorType } = errorSlice.actions;

export default errorSlice.reducer;
