import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const efficiencyRatioAnalysisSlice = createApi({
  reducerPath: "analysis_efficiency_ratio",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["efficiency_ratio"],
  endpoints: (build) => ({
    getEfficiencyRatioSummary: build.query({
      query: ({ orgId, headers, payload, subtype }) => ({
        url: `bank/${orgId}/dashboard?card=efficiency_ratio&view=summary&page=0&size=10&subtype=${subtype}`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),
  }),
});

export const { useGetEfficiencyRatioSummaryQuery } =
  efficiencyRatioAnalysisSlice;
