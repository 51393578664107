import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const fileAnalyticsAnalysisSlice = createApi({
  reducerPath: "analysis_file_analytics",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["file_analytics"],
  endpoints: (build) => ({
    getFileAnalyticsExpanded: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=file_analytics&view=expand&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),
  }),
});

export const { useGetFileAnalyticsExpandedQuery } = fileAnalyticsAnalysisSlice;
