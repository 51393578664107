// import { useSelector } from "react-redux";

import { Modal } from "react-bootstrap";
import { GetFromServer } from "../../../../utils/GetFromServer";
import exportimages from "../../../../assets/images/exportImages";
import { toastResponse } from "../../../../helper components/Toast/Toast";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { formatCategoryName } from "../../../Bank/report/widget/widgetsCommonFunction";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import "./UpgradeCardPopUp.scss";
// import { formatCategoryName } from "../../../Bank/report/widget/widgetsCommonFunction";

export const ContactSalesTeamModal = (props) => {
  // console.log(props);
  const [currentToast, setCurrentToast] = useState("");
  const [successFlag, setSuccessFlag] = useState(false);
  const params = useParams();
  const authReducer = useSelector((state) => state.authReducer);
  const userRole = authReducer.userData.role?.toLowerCase();
  // const subscriptionReducer = useSelector((state) => state.subscriptionReducer);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSuccessFlag(false);
  }, [props]);
  const navigate = useNavigate();
  const showToast = (message, status) => {
    if (message !== currentToast) {
      if (status === "error") {
        toast.error(toastResponse("error", message));
      } else {
        toast.success(toastResponse("success", message));
      }
      setCurrentToast(message);
      setTimeout(() => setCurrentToast(""), 5500);
    }
  };
  const handleClick = async () => {
    // const recipient = "sales@bond.ai";
    // const subject = "Upgrade to get Pro access";
    // const body = "";

    // // Constructing a mailto link
    // const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
    //   subject,
    // )}&body=${encodeURIComponent(body)}`;

    // // Open the mail client
    // window.location.href = mailtoLink;

    // let existing = subscriptionReducer?.isPro
    //   ? subscriptionReducer?.isMarketing
    //     ? "Marketing"
    //     : "Pro"
    //   : "Freemium";

    if (successFlag) {
      // navigate(`/bank/health-analysis/report/${params?.hid}`);
      props.onHide();

      let currentUrl = window.location.href;
      if (currentUrl.includes("/financial-health-score")) {
        navigate(`/bank/health-analysis/report/${params?.hid}`);
      } else if (currentUrl.includes("/drilldown/graph")) {
        navigate(`/bank/health-analysis/report/${params?.hid}`);
      }
    } else {
      let existing =
        props?.cardData?.name !== "upgrade_email_campaign" ? "Freemium" : "Pro";
      let involved =
        existing === "Freemium"
          ? "Pro"
          : existing === "Pro"
            ? "Marketing"
            : "Pro";
      setLoading(true);
      try {
        const response = await GetFromServer(
          `/bank/healthanalysis/pro/request?vFeature=${formatCategoryName(
            props?.cardData?.name,
          )}&vExistingTier=${existing}&vInvolvedTier=${involved}`,
          {},
        );
        if (response.data.status === "SUCCESS") {
          setSuccessFlag(true);
          // showToast("Email sent to sales team successfully.", "success");
          // props.onHide();
        }
      } catch (error) {
        showToast(error?.data?.message, "error");
      }
      // props.onHide();
      setLoading(false);
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      restoreFocus={"true"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="chamelonTourPopup"
    >
      <Modal.Header className="p-0 m-0 d-none" closeButton />
      <Modal.Body
        style={{ padding: "0px", position: "relative", overflow: "hidden" }}
        className="pt-0 m-0"
        closeButton
      >
        <img
          className="position-absolute cursor-pointer"
          style={{ right: "15px", top: "15px" }}
          src={exportimages.FU_cross}
          onClick={() => props.onHide()}
          alt=""
        />
        <div className="d-flex ">
          <div className="w-50">
            <img
              src={
                successFlag
                  ? exportimages?.contact_sales_team_success_upgrade
                  : exportimages?.contact_sales_team_upgrade
              }
              style={{left:"-0.5%", position:"relative",height:"100%"}}
              // className="p-9"
              alt=""
            />
          </div>

          <div
            class="d-flex align-items-start flex-column bd-highlight mb-2 p-12 p-xxl-9"
            // style="height: 200px;"
          >
            <div class="mb-auto p-2 bd-highlight">
              <h3
                className="text-dark fw-bolder lh-lg mb-0"
                style={{ fontSize: "1.5rem" }}
              >
                {successFlag
                  ? "Sales Contacted Successfully"
                  : "Autopilot your bank's growth"}
              </h3>
              <div
                className="text-muted fs-5 fs-3x-6 mt-4"
                // dangerouslySetInnerHTML={{
                //   __html: props?.cardData ? props?.cardData?.description : "NA",
                // }}
              >
                {successFlag ? (
                  <>
                    <p>
                      Your message has been successfully sent to our Sales team.
                      They will be in touch with you shortly.
                    </p>
                    <p className="mt-4">Thank you for reaching out!</p>
                  </>
                ) : (
                  <>
                    <p>
                    We can’t wait to see your institution grow with Autopilot from BOND.AI. Our team will be in touch with you very soon.
                    </p>

                    <p style={{display: userRole === "reseller" || userRole === "bond-admin"? "none":"block"}}>
                    {/* They will be in touch shortly to assist you further. */}
                    </p>
                  </>
                )}
              </div>
            </div>
            <div
              class="p-2 bd-highlight"
              style={{
                display:
                  userRole === "reseller" || userRole === "bond-admin"
                    ? "none"
                    : "block",
              }}
            >
              {!successFlag && (
                <button
                  type="button"
                  id="kt_login_password_reset_form_cancel_button "
                  // onClick={() => props.onHide()}
                  className="btn btn-other btn-bond px-8 py-3"
                  data-toggle="collapse"
                  href="#collapseTransactionBlock"
                  // role="button"
                  aria-expanded="false"
                  aria-controls="collapseTransactionBlock"
                  style={{pointerEvents:loading?"none":"all"}}
                  onClick={() => {
                    handleClick();
                    // props.onHide();
                    // setContactSalesModal(true);
                  }}
                >
                  <span
                    className="me-1 fs-5 fs-xxl-6"
                    style={{ fontWeight: "normal" }}
                  >
                    {successFlag ? "Dashboard" : "Contact Sales"}
                  </span>
                  <i
                    className="spinner-grow spinner-grow-sm ms-3"
                    id="btn_spinner"
                    role="status"
                    style={{ display: loading ? "inline-flex" : "none" }}
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
