import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoaderFalse, setLoaderTrue } from "../../Redux/loaderSlice";

export const Fallback = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // Show the loader immediately on mount
    dispatch(setLoaderTrue());

    // Return a cleanup function to hide the loader when the component unmounts
    return () => {
      dispatch(setLoaderFalse());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div />;
};
