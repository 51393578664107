import "./CohortManagement.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import exportimages from "../../../assets/images/exportImages";
import { useDispatch, useSelector } from "react-redux";
import CohortFilter from "./CohortFilter";
import { Button } from "primereact/button";
import CohortDisplayData from "./CohortDisplayData";
import CohortSearchData from "./CohortSearchData";
import { useEffect, useRef, useState } from "react";
import searchLogo from "../../../assets/images/Search.png";
import { Checkbox } from "primereact/checkbox";
import { VirtualScroller } from "primereact/virtualscroller";
import { PostToServer } from "../../../utils/PostToServer";
import CardDisplay from "./CardDisplay";
import InstitutionUpdationList from "./InstitutionUpdationList";
import {
  resetCohortData,
  setAdditionalInstitutionData,
  setAssetSize,
  setCheckedInstitutionItems,
  setDisplayAdditionalInsitituteData,
  setOriginalData,
  setRemoveDisplayAdditionalInsitituteData,
  setSelectCategory,
  setSelectedIndex,
  setSelectStates,
} from "../../../Redux/cohortSlice";
import { Form, Modal } from "react-bootstrap";
import { setLoaderFalse, setLoaderTrue } from "../../../Redux/loaderSlice";
import store from "../../../app/store";
import { DeleteToServer } from "../../../utils/DeleteToServer";
import CohortAdditionalInstitution from "./CohortAdditionalInstitution";
import { allCohortThunk } from "../../../Redux/allCohortRedux";
import { selectFinancialHealthScoreExpanded } from "../../../rtk-query/features/dashboard/financialHealthScore/financialHealthScoreAnalysisSlice";
import { putToServer } from "../../../utils/putToServer";
import { capitalizeFirstLetterOfEachWord } from "../report/widget/widgetsCommonFunction";

export const CohortManagement = () => {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const orguid = store.getState().orgReducer.selectedFI?.orguid;

  const allCohortObj = useSelector((state) => state?.cohortSlice)
  const [data, setData] = useState(allCohortObj?.institutionData)

  const allCohortObject = useSelector((state) => state.allCohortReducer);
  const filteredCohortObject =
    allCohortObject?.allCohort?.filter((obj) => obj?.cohort_type !== 1) || [];

  const [searchData, setSearchData] = useState("");
  const [originalCohortData, setOriginalCohortData] = useState([]);
  const [filterCohortData, setFilterCohortData] = useState([]);
  const [institutionDataArr, setInstitutionDataArr] = useState(allCohortObj?.institutionData);
  const [showInstitutionData] = useState([]);
  const [filterDataToDisplay, setFilterDataToDisplay] = useState({});
  const [saveOperation, setSaveOperation] = useState("Create");
  const [cardDisplay, setCardDisplay] = useState(false);
  const [loaderVisibleForSelectingCohort, setLoaderVisibleForSelectingCohort] =
    useState(true);
  const [containerHeight, setContainerHeight] = useState(300); // State for height
  const containerRef = useRef(null);


  //searching the addition institution by normal array
  const [inputValue, setInputValue] = useState("");
  let [showNotFound, setShowNotFound] = useState(false);
  const [selectedSortInstitution, setSelectedSortInstitution] = useState();
  const [inputValueInstitution, setInputValueInstitution] = useState("");

  const virtualScrollerAdditionalInstitutionRef = useRef(null);
  const [error, setError] = useState("");


  const resetScrollPositionSelectedInstitution = () => {
    if (virtualScrollerAdditionalInstitutionRef.current) {
      virtualScrollerAdditionalInstitutionRef.current.scrollToIndex(0, "auto");
    }
  };
  const resetScrollPositionSelectedRemoveInstitution = () => {
    if (virtualScrollerAdditionalInstitutionRef.current) {
      // First, scroll to the first item
      virtualScrollerAdditionalInstitutionRef.current.scrollToIndex(0, "start");

      // Then, adjust the scroll position by a certain amount (e.g., -50 pixels)
      const offset = -50; // Adjust this value as needed
      const scrollElement = virtualScrollerAdditionalInstitutionRef.current;
      scrollElement.scrollTop += offset;
    }
  };


  const [saveCohortModal, setSaveCohortModal] = useState({
    saveCohortModalStage1: false,
    saveCohortModalStage2: false,
    saveCohortModalStage3: false,
  });
  const { payload: FHSExpanded } = useSelector((state) =>
    selectFinancialHealthScoreExpanded(state),
  );
  const instituteinputRef = useRef(null);
  const institutedropdownRef = useRef(null);
  const institutedropdowniconRef = useRef(null);

  const [showInstitutionOption, setShowInstitutionOption] = useState(false);

  const [cohortName, setCohortName] = useState("");
  const [description, setDescription] = useState("");
  // const [saveCohortName, setSaveCohortName] = useState("");
  const virtualScrollerInstitutionRef = useRef(null);
  const [resetFlag, setResetFlag] = useState(false);
  let path = useLocation();
  useEffect(() => {
    setInstitutionDataArr(allCohortObj?.institutionData);
    setData(allCohortObj?.institutionData)
  }, [allCohortObj?.institutionData]);


  async function handleDataForDisplayInstitute(selectedItemObj) {
    let finalObj = {
      filter: {
        assets: { ...selectedItemObj?.filter_data?.assests },
        state: selectedItemObj?.filter_data?.state,
        type: selectedItemObj?.filter_data?.type,
        institutions: { ...selectedItemObj?.filter_data?.institutions },
      },
    };

    try {
      if (filteredCohortObject?.length !== 0) {
        if (loaderVisibleForSelectingCohort) {
          dispatch(setLoaderTrue());
        }
        const orguid = store.getState().orgReducer.selectedFI?.orguid;
        const response = await PostToServer(
          `bank/${orguid}/cohort/filter?card=cohort&view=filter&searchby=${encodeURIComponent(
            "",
          )}&page=1&size=10`,
          finalObj,
        );

        dispatch(
          setDisplayAdditionalInsitituteData(response?.data?.data[0]?.selected),
        );

        dispatch(setOriginalData(response?.data?.data[0]?.selected));

        dispatch(
          setAdditionalInstitutionData(response?.data?.data[0]?.unselected),
        );
        dispatch(
          setRemoveDisplayAdditionalInsitituteData(
            response?.data?.data[0]?.removed,
          ),
        );
        dispatch(setCheckedInstitutionItems(response?.data?.data[0]?.added));
        setInputValueInstitution("");
        setInputValue("");
      }
    } catch (e) {
      console.log(e);
      dispatch(setLoaderFalse());
    } finally {
      dispatch(setLoaderFalse());
    }
  }

  useEffect(() => {
    const latestCohortObj = filteredCohortObject.slice().sort((a, b) => {
      const dateA = new Date(a.createdon);
      const dateB = new Date(b.createdon);
      return dateB - dateA;
    });
    //initially cohort should set to first position
    const firstItem = latestCohortObj[0] ? latestCohortObj[0] : {}; // Get the first item
    const initialItemId = firstItem ? firstItem.uid : null;

    const selectedItemObj =
      latestCohortObj.find((item) => item.uid === allCohortObj?.selectedIndex) || firstItem;

    if (filterCohortData?.length === 0) {
      setShowNotFound(false);
      dispatch(resetCohortData())
    }

    if (initialItemId && allCohortObj?.selectedIndex === null) {
      dispatch(setSelectedIndex(initialItemId));
    } else if (initialItemId === undefined || initialItemId === null) {
      const defaultCohort = allCohortObject?.allCohort?.find(
        (item) => item?.cohort_type === 1,
      );
      if (defaultCohort) {
        dispatch(setSelectedIndex(defaultCohort?.uid));
      }
    } else {
      dispatch(setSelectedIndex(selectedItemObj?.uid));
    }

    let parsingCohortObj =
      Object.keys(selectedItemObj)?.length > 0
        ? JSON.parse(selectedItemObj.cohort_data)
        : {};

    setFilterCohortData(latestCohortObj);
    setOriginalCohortData(latestCohortObj);
    setCohortName(selectedItemObj?.name);
    setDescription(selectedItemObj?.description);
    setFilterCohortData(latestCohortObj);

    handleDataForDisplayInstitute(selectedItemObj);

    if (latestCohortObj?.length !== 0) {
      setSaveOperation("Edit");
      setShowNotFound(true)
    } else {
      setSaveOperation("Create");
      setShowNotFound(false)
    }
    setFilterDataToDisplay({ ...selectedItemObj, ...parsingCohortObj });

    //this is for carddisplay
    if (latestCohortObj?.length > 0) {
      setCardDisplay(true);
    } else {
      setCardDisplay(false);
    }
    const filteredStates = allCohortObj?.filteredCities?.filter((state) =>
      selectedItemObj?.filter_data?.state?.includes(state?.name),
    );

    dispatch(setSelectStates(filteredStates));
    if (Object.keys(selectedItemObj)?.length === 0) {
      dispatch(setAssetSize({ min: "", max: "" }));
    } else {
      dispatch(setAssetSize(selectedItemObj?.filter_data?.assests));
    }
    dispatch(setSelectCategory(selectedItemObj?.filter_data?.type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCohortObject]);

  const handleFilterSearchData = (input) => {
    const filteredData = originalCohortData.filter(
      (data) =>
        data?.name?.toLowerCase().includes(input.toLowerCase()) ||
        data?.description?.toLowerCase().includes(input.toLowerCase()),
    );
    setFilterCohortData(filteredData);
  };

  const handleCohortData = (data, index) => {
    dispatch(setSelectedIndex(data?.uid));
    setCardDisplay(true);
    let parsingCohortObj = data ? JSON.parse(data?.cohort_data) : {};
    setFilterDataToDisplay({ ...data, ...parsingCohortObj });
    setError("");
    setCohortName(data?.name);
    setDescription(data?.description);
    setSaveOperation("Edit");
    setShowNotFound(true)
    setSelectedSortInstitution("")
    setLoaderVisibleForSelectingCohort(true);
    handleDataForDisplayInstitute(data);

    const filteredStates = allCohortObj?.filteredCities?.filter((state) =>
      data?.filter_data?.state.includes(state.name),
    );
    dispatch(setSelectStates(filteredStates));
    dispatch(setAssetSize(data?.filter_data?.assests));
    dispatch(setSelectCategory(data?.filter_data?.type));

    resetScrollPositionSelectedInstitution();
    resetScrollPosition();
  };



  //handling the delete item
  const handleCohortDeleteItem = async (e, uid) => {
    e.stopPropagation();
    setLoaderVisibleForSelectingCohort(false);
    try {
      dispatch(setLoaderTrue());
      const deleteResponse = await DeleteToServer(
        `bank/${orguid}/cohort/${uid}`,
      );

      if (deleteResponse?.data?.status === "SUCCESS") {
        dispatch(allCohortThunk());
        if (filterCohortData?.length === 1) {
          dispatch(resetCohortData())
          setShowNotFound(false)
        }
        setLoaderVisibleForSelectingCohort(true);
      }
      setSelectedSortInstitution("");
    } catch (e) {
      console.log(e);
    }
  };
  function handleDropdownToggle(e) {
    e.preventDefault();
    setShowInstitutionOption((prevShowOptions) => !prevShowOptions);
    e.stopPropagation();
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !instituteinputRef?.current?.contains(event.target) &&
        !institutedropdownRef?.current?.contains(event.target) &&
        !institutedropdowniconRef?.current?.contains(event.target)
      ) {
        setShowInstitutionOption(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const onAdditionalCategoryChange = (e) => {
    let data = e?.value;
    let checked = e?.checked;
    let updatedItems = [];
    if (checked) {
      updatedItems = [...(allCohortObj?.checkedItems || []), data];
    } else {
      updatedItems = allCohortObj?.checkedItems.filter(
        (item) => item?.idrssd !== data?.idrssd,
      );
    }
    if (checked) {
      if (
        !allCohortObj?.displayAdditionalInsitituteData.some(
          (item) => item.idrssd === data.idrssd,
        )
      ) {
        dispatch(
          setOriginalData([...(allCohortObj?.originalData || []), data]),
        );
        dispatch(
          setDisplayAdditionalInsitituteData([
            ...(allCohortObj?.displayAdditionalInsitituteData || []),
            data,
          ]),
        );
      }
    } else {
      const updatedData = allCohortObj?.displayAdditionalInsitituteData.filter(
        (item) => item.idrssd !== data?.idrssd,
      );
      const updatedDataForOriginalAllData = allCohortObj?.originalData?.filter(
        (item) => item?.idrssd !== parseInt(data?.idrssd, 10),
      );
      const removedDataForInstitution = allCohortObj?.removeDisplayAdditionalInstituteData?.filter(
        (item) => item?.idrssd !== data?.idrssd
      )
      const unselectedDataForInstitution = allCohortObj?.institutionData.find((item) => allCohortObj?.removeDisplayAdditionalInstituteData?.some((data) => data?.idrssd === item?.idrssd))
      dispatch(setAdditionalInstitutionData([...allCohortObj?.institutionData, unselectedDataForInstitution]))
      dispatch(setOriginalData(updatedDataForOriginalAllData));
      dispatch(setDisplayAdditionalInsitituteData(updatedData));
      dispatch(setRemoveDisplayAdditionalInsitituteData(removedDataForInstitution))
    }

    dispatch(setCheckedInstitutionItems(updatedItems));
  };


  const handleOpenSaveStage2CohortModal = async () => {
    resetScrollPositionSelectedInstitution();
    resetScrollPosition();
    const removedIds =
      allCohortObj?.removeDisplayAdditionalInstituteData?.map((item) =>
        parseInt(item?.idrssd, 10),
      ) || [];

    const addedIds =
      allCohortObj?.checkedItems?.map((item) => parseInt(item?.idrssd, 10)) || [];
    const selectedIds = allCohortObj?.originalData.map((item) => parseInt(item?.idrssd, 10)) || [];

    const unselectedIds =
      institutionDataArr.map((item) => parseInt(item?.idrssd, 10)) || [];

    let finalObj = {
      name: cohortName?.trim(),
      description: description.trim(),
      metadata: {
        assests: {
          min: parseInt(allCohortObj?.assetSize?.min, 10) || 0,
          max: parseInt(allCohortObj?.assetSize?.max, 10) || 0,
        },
        state: allCohortObj?.selectedStates?.map((state) => state?.name),
        type: FHSExpanded?.fi_type,
        institutions: {
          selected: selectedIds,
          added: addedIds,
          removed: removedIds,
          unselected: unselectedIds,
        },
      },
    };
    setLoaderVisibleForSelectingCohort(false);
    try {
      dispatch(setLoaderTrue());
      if (saveOperation === "Create") {
        let response = await PostToServer(`bank/${orguid}/cohort`, finalObj);
        setSaveCohortModal({
          saveCohortModalStage1: false,
          saveCohortModalStage2: false,
          saveCohortModalStage3: true,
        });
        dispatch(setSelectedIndex(response?.data?.data[0]?.cohort_id));
        dispatch(allCohortThunk());
      } else {
        await putToServer(
          `bank/${orguid}/cohort/${filterDataToDisplay?.uid}`,
          finalObj,
        );
        setSaveCohortModal({
          saveCohortModalStage1: false,
          saveCohortModalStage2: false,
          saveCohortModalStage3: true,
        });
        dispatch(allCohortThunk());
      }
      setSelectedSortInstitution("");
      setLoaderVisibleForSelectingCohort(true);
    } catch (e) {
      console.log(e);
      dispatch(setLoaderFalse());
    }
  };


  const handleCloseSaveStage3CohortModal = () => {
    setSaveCohortModal({
      saveCohortModalStage1: false,
      saveCohortModalStage2: false,
      saveCohortModalStage3: false,
    });
    navigate(
      `/bank/health-analysis/report/${path?.state?.analysisID?.hid}/financial-health-score`,
    );
  };

  //virtual scroll for  additional-institution


  const itemTemplate = (item, options) => {
    return (
      <div className="dropdown-item" style={{ width: "100%" }}>
        {/* <CheckboxComponent onChange={onCategoryChange} arrayData={institutionDataArr} name="insitution" selectedCheckBoxValue={checkedItems} /> */}
        <div key={options?.idrssd} className="">
          <Checkbox
            inputId={item?.idrssd}
            name={"list"}
            className="checkbox pt-2"
            value={item}
            // onChange={onCategoryChange}
            onChange={onAdditionalCategoryChange}
            checked={
              (allCohortObj?.checkedItems.length > 0 &&
                allCohortObj?.checkedItems?.some(
                  (data) => data?.idrssd === item?.idrssd,
                )) ||
              false
            }
          />
          <label
            htmlFor={item?.idrssd}
            className="checkboxLabel font-color form-check-label fw-bold cursor-pointer pt-2 ms-2"
          >
            {item?.institutionName}
          </label>
        </div>
      </div>
    );
  };

  const resetScrollPosition = () => {
    if (virtualScrollerInstitutionRef.current) {
      virtualScrollerInstitutionRef.current.scrollToIndex(0, "auto");
    }
  };

  // Handle input change
  const handleChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    if (newValue === '') {
      // If search query is empty, show all items
      setInstitutionDataArr(data);
    } else {
      // Filter data based on the search query
      const filtered = data.filter(item =>
        item?.institutionName?.toLowerCase()?.includes(newValue?.toLowerCase())
      );
      setInstitutionDataArr(filtered);
    }

  };
  const handleBack = (e) => {
    navigate(
      `/bank/health-analysis/report/${path?.state?.analysisID?.hid}/financial-health-score`,
    );
  };

  const handleClearAddInstitution = (e) => {
    e.preventDefault();
    const updatedList = allCohortObj?.displayAdditionalInsitituteData.filter(
      (item) =>
        !allCohortObj?.checkedItems.some(
          (removeItem) => removeItem.idrssd === item.idrssd,
        ),
    );

    dispatch(setDisplayAdditionalInsitituteData(updatedList));
    dispatch(setCheckedInstitutionItems([]));
  };
  const handleReset = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSaveOperation("Create");
    dispatch(resetCohortData());
    setCohortName("");
    setDescription("");
    setInputValue("");
    setInputValueInstitution("");
    setError("");
    setCardDisplay(false);
    setSelectedSortInstitution("");
    setContainerHeight(390)
    setShowNotFound(false);


    resetScrollPositionSelectedInstitution();
    resetScrollPosition();
    setShowNotFound(false);
  };

  useEffect(() => {
    // Update the height dynamically based on the number of items
    if (containerRef.current) {
      const height = Math.max(containerRef.current.scrollHeight, 779); // Calculate max height
      setContainerHeight(height - 390);
    }
  }, [allCohortObj?.checkedItems, allCohortObj?.removeDisplayAdditionalInstituteData]);
  return (
    <div
      className="px-10 pt-5 pb-10 contentWrapper dashboardContainer position-relative "
      id="alldata"
    >
      <div className="row g-0 g-xl-5 g-xxl-8">
        <div className="col-md-5 col-sm-12">
          <div className="custom-theme pageTitle">
            <h3 className="text-dark fs-1 fw-bolder lh-lg mb-1 dashboard-title">
              Cohort Details
            </h3>
          </div>
          <div
            className="custom-theme pageSubTitle text-868687 "
            style={{ fontSize: "12px" }}
          >

            <span
              className="cursor-pointer"
            >
              <Link
                id="graph_link_to_report"
                to={`/bank/health-analysis/report/${path?.state?.analysisID?.hid}`}
                style={{ color: "#868687" }}
              >
                Dashboard
              </Link>
              {" > "}
            </span>
            <span className="fw-normal cursor-pointer">
              {" "}
              <Link
                id="graph_link_to_report"
                to={`/bank/health-analysis/report/${path?.state?.analysisID?.hid}/financial-health-score`}
                style={{ color: "#868687" }}
              >
                Financial Health Score
              </Link>
              {" > "}
            </span>
            <span className="text-4b4b4b text-decoration-underline">
              {" "}
              Cohort Management
            </span>
          </div>
        </div>
        <div
          className="col-md-7 col-sm-12 text-end mb-4"
          style={{ fontWeight: "normal!important" }}
        >
          <button
            type="button"
            id="kt_login_password_reset_form_cancel_button"
            onClick={(e) => handleBack(e)}
            style={{ marginRight: "10px" }}
            className="mr-4 btn btn-outline-primary border-primary btn-bond mb-2 mt-2 px-3 py-3 px-xxl-3 btn-lg fs-5  w-20"
          >
            <img
              alt="back-arrow"
              src={exportimages?.backArrowNew}
              className="w-5px me-2 align-baseline img-fluid arrowImage"
            />
            <img
              alt="back-arrow"
              src={exportimages?.hoverBackArrowNew}
              className="w-5px me-2 align-baseline img-fluid arrowImageHover"
            />
            <span
              className="me-3 fs-5 fs-xxl-6 "
              style={{ fontWeight: "normal" }}
            >
              Back{" "}
            </span>
          </button>
          <button
            type="button"
            style={{ marginRight: "10px", backgroundColor: "#e2fcff" }}
            className="mr-4 btn btn-outline-primary border-primary btn-bond mb-2 mt-2 px-3 py-3 px-xxl-3 btn-lg fs-5 w-20"
            onClick={(e) => {
              e.preventDefault();
              setSaveCohortModal({
                ...saveCohortModal,
                saveCohortModalStage2: true,
              });
            }}
            disabled={
              allCohortObj?.displayAdditionalInsitituteData?.length === 0
            }
          >
            <span className="fs-5 fs-xxl-6" style={{ fontWeight: "normal" }}>
              Save Cohort
            </span>
          </button>
        </div>
      </div>
      <div className="row g-0 g-xl-5 g-xxl-8 d-flex justify-content-between">
        <div className="row g-0 g-xl-5 g-xxl-8 justify-content-md-center">
          <div className="col-md-12">
            <div className="row ms-1 cohort-management">
              <div className="col-md-3 px-0 bg-white cohort">
                <div className="py-5">
                  <div className="px-7">
                    <h3 className="mb-3">Cohort</h3>
                    <div className="row">
                      <div className="col-md-8 p-0">
                        <CohortSearchData
                          setSearchData={setSearchData}
                          searchData={searchData}
                          handleFilterSearchData={handleFilterSearchData}
                        />
                      </div>
                      <div className="col-md-4 ps-3 pe-0 float-end">
                        <Button
                          type="button"
                          className="apply-filter float-end border-none"
                          onClick={handleReset}
                          disabled={resetFlag}
                        >
                          + Cohort
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="cohort-display-height">
                    <div className="diviser text-muted" />
                    {filterCohortData && filterCohortData.length > 0 ? (
                      filterCohortData.map((data, index) => (
                        <div
                          className={`pb-0 pt-7 ${allCohortObj?.selectedIndex === data?.uid ? "cohort-bgcolor" : ""
                            }`}
                          key={data?.uid}
                          style={{ borderRadius: "0" }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleCohortData(data, index);
                          }}
                        >
                          <CohortDisplayData
                            datas={data}
                            handleCohortDeleteItem={handleCohortDeleteItem}
                            filterCohortData={filterCohortData}
                          />
                        </div>
                      ))
                    ) : (
                      <div className="noCohortBlock">
                        <div className=" text-center w-100 d-flex flex-column align-items-center justify-content-between">
                          <div className="mt-10">
                            <div className="col-md-12 mb-8 mt-20">
                              <img
                                alt="logo"
                                src={exportimages.cohortNoData}
                                className="group_3"
                              // style={{ width: "28vw" }}
                              />
                            </div>
                            <div className="col-md-12">
                              <p
                                className="blockTitle flex-grow-1 fs-1  mb-4"
                                style={{ fontWeight: 900 }}
                              >
                                Create a New Cohort
                              </p>
                            </div>
                            <div className="col-md-12">
                              <p className="mb-0 d-flex justify-content-center align-items-center fs-5 fs-xxl-6 ">
                                <span style={{ width: "65%" }}>
                                  Currently, no cohort is available. To create
                                  one, please add institutions and apply filters
                                  to form a new cohort. This will help you group
                                  institutions according to your requirements.
                                </span>
                              </p>
                            </div>
                          </div>
                          <div />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div
                  className="row justify-content-start ps-5 pe-8 py-8"
                  style={{ display: "flex" }}
                >
                  <div ref={containerRef} className="col-md-6 pe-8 d-flex flex-column">
                    <CohortFilter
                      error={error}
                      setError={setError}
                      resetScrollPositionSelectedInstitution={
                        resetScrollPositionSelectedInstitution
                      }
                      setSelectedSortInstitution={setSelectedSortInstitution}
                      saveOperation={saveOperation}
                      setShowNotFound={setShowNotFound}
                    />
                    <div className="rounded bg-white mt-8 py-5 flex-grow-1">
                      <div className="px-7">
                        <div className="d-flex justify-content-between">
                          <h3 className="pb-0 mb-0">Additional Institutions</h3>
                          <button
                            className="btn btn-link text-muted p-0 m-0"
                            onClick={handleClearAddInstitution}
                          >
                            Clear
                          </button>
                        </div>
                        <div className="mt-1 w-75">
                          Select institutions from the list that are not in the
                          cohort.Filters may not apply to these.
                        </div>
                        <div>
                          <div className="search-field w-100 mt-3 w-sm-100 w-xs-100 position-relative">
                            <div className="static-img">
                              <img
                                src={searchLogo}
                                alt="icon"
                                className="searchicon"
                              />
                            </div>
                            <input
                              ref={instituteinputRef}
                              type="text"
                              className="form-control login-registration-form custom-input-field"
                              placeholder="Search Additional Institutions"
                              value={inputValue}
                              onFocus={(e) => {
                                setShowInstitutionOption(true);
                                e.stopPropagation();
                              }}
                              onChange={(e) => handleChange(e)}
                            />
                            <button
                              ref={institutedropdowniconRef}
                              className="pi pi-chevron-down text-muted dropdown-icon institution-dropdown"
                              style={{
                                position: "absolute",
                                right: "2%",
                                top: "42%",
                                background: 'none',
                                border: 'none',
                                padding: 0
                              }}
                              onClick={handleDropdownToggle}
                            />
                            {showInstitutionOption && (
                              <div
                                ref={institutedropdownRef}
                                className="card-dropdown w-100"
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: 0,
                                  zIndex: 100,
                                }}
                              >
                                <div className="card flex flex-wrap justify-content-center gap-5">
                                  {
                                    institutionDataArr?.length > 0 ? (
                                      <VirtualScroller
                                        ref={virtualScrollerInstitutionRef}
                                        items={institutionDataArr}
                                        itemSize={30}
                                        itemTemplate={itemTemplate}
                                        style={{ width: "100%", height: "200px" }}
                                        delay={0}
                                        lazy
                                      />
                                    ) : (
                                      <div className="p-3">No Data Found</div>
                                    )
                                  }

                                </div>
                              </div>
                            )}
                          </div>
                          <div className="institution-height">
                            <CohortAdditionalInstitution
                              checkedItems={allCohortObj?.checkedItems}
                              onCategoryChange={onAdditionalCategoryChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 px-0 d-flex flex-column rounded">
                    <CardDisplay
                      data={filterDataToDisplay}
                      cardDisplay={cardDisplay}
                    />
                    <div className="additional-institution flex-grow-1">
                      <InstitutionUpdationList
                        cardData={filterDataToDisplay}
                        showInstitutionData={showInstitutionData}
                        inputValueInstitution={inputValueInstitution}
                        setInputValueInstitution={setInputValueInstitution}
                        resetScrollPositionSelectedInstitution={
                          resetScrollPositionSelectedInstitution
                        }
                        resetScrollPositionSelectedRemoveInstitution={
                          resetScrollPositionSelectedRemoveInstitution
                        }
                        ref={virtualScrollerAdditionalInstitutionRef}
                        saveOperation={saveOperation}
                        showNotFound={showNotFound}
                        setShowNotFound={setShowNotFound}
                        cardDisplay={cardDisplay}
                        selectedSortInstitution={selectedSortInstitution}
                        setSelectedSortInstitution={setSelectedSortInstitution}
                        containerHeight={containerHeight}
                        setResetFlag={setResetFlag}
                        resetFlag={resetFlag}
                      />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <SaveCohortModal
        saveCohortName={saveCohortName}
        saveOperation={saveOperation}
        setSaveCohortName={setSaveCohortName}
        show={saveCohortModal?.saveCohortModalStage1}
        onHide={handleCloseSaveStage1CohortModal}
        onSaveAndContinue={handleSaveAndContinueCohort}
      /> */}
      <AddCohortModal
        cohortName={cohortName}
        saveOperation={saveOperation}
        description={description}
        setCohortName={setCohortName}
        setDescription={setDescription}
        show={saveCohortModal?.saveCohortModalStage2}
        onHide={() => {
          setSaveCohortModal({
            saveCohortModalStage1: false,
            saveCohortModalStage2: false,
            saveCohortModalStage3: false,
          });
        }}
        onSave={handleOpenSaveStage2CohortModal}
      />
      <SaveCohortSuccessModal
        show={saveCohortModal?.saveCohortModalStage3}
        onHide={() => {
          setSaveCohortModal({
            saveCohortModalStage1: false,
            saveCohortModalStage2: false,
            saveCohortModalStage3: false,
          });
        }}
        onSave={handleCloseSaveStage3CohortModal}
        cohortName={cohortName}
      />
    </div>
  );
};
export default CohortManagement;

//NOSONAR const SaveCohortModal = ({
//     saveCohortName,
//     setSaveCohortName,
//     show,
//     onHide,
//     onSaveAndContinue,
//     saveOperation
// }) => {
//     let selectedCategoryData = useSelector(
//         (state) => state?.cohortSlice?.selectedCategory,
//     );
//     const handleInputChange = (event) => {
//         setSaveCohortName(event.target.value);
//     };
//     const isButtonDisabled = saveCohortName?.trim() === "";

//     return (
//         <Modal show={show} onHide={onHide} size="md" centered>
//             <Modal.Header className="mx-4 mt-3 px-5 pb-0" closeButton>
//                 <Modal.Title>Save Cohort</Modal.Title>
//             </Modal.Header>
//             <Modal.Body className="pt-0 pb-2">
//                 <div>
//                     <p className="fw-semibold mt-1 fs-7 mb-7 w-90 mb-3 text-dark">
//                         A bank cohort obtained by filtering is a group of banks selected
//                         based on demographics, asset size, and bank type for comparative
//                         analysis.
//                     </p>

//                     <div className="fw-bolder text-dark fs-3 blockTitle w-100 mt-2 mb-4">
//                         Save Cohort As
//                     </div>

//                     <div>
//                         <Form.Control
//                             type="text"
//                             className="login-registration-form form-control mb-2 form-control-lg form-control-solid"
//                             id="basic-url"
//                             aria-describedby="basic-addon3"
//                             value={saveCohortName}
//                             onChange={handleInputChange}
//                         />
//                     </div>

//                     <div className="d-flex justify-content-between">
//                         <div className="fw-bolder text-dark fs-3 blockTitle mt-2">
//                             Filters Selected
//                         </div>
//                         <div className="mt-2">
//                             <div className="fw-bolder text-dark float-end">
//                                 Banks <span className="fw-normal">233</span>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="fw-bolder fs-3 blockTitle mt-2">
//                         FI Type: <span className="fw-light">{selectedCategoryData}</span>
//                     </div>

//                     <div className="border-top-0 py-5 float-end">
//                         <button
//                             type="button"
//                             className="btn btn-primary btn-bond fw-bolder fs-5 fs-xxl-6 px-10 py-4"
//                             onClick={onSaveAndContinue}
//                             disabled={isButtonDisabled}
//                         >
//                             Save and Continue
//                         </button>
//                     </div>
//                 </div>
//             </Modal.Body>
//         </Modal>
//     );
// };

const AddCohortModal = ({
  cohortName,
  description,
  setCohortName,
  setDescription,
  show,
  onHide,
  onSave,
  saveOperation,
}) => {

  let allCohortObj = useSelector((state) => state?.cohortSlice)
  const { payload: FHSExpanded } = useSelector((state) =>
    selectFinancialHealthScoreExpanded(state),
  );
  // Handler for input changes
  const handleNameChange = (event) => {
    const input = event.target;
    const cursorPosition = input.selectionStart; // Record the cursor position

    // Get the current value, capitalize it, and set it to state
    const newValue = capitalizeFirstLetterOfEachWord(input.value);
    setCohortName(newValue);

    // Use a callback to restore the cursor position after the state has updated
    setTimeout(() => {
      input.selectionStart = cursorPosition;
      input.selectionEnd = cursorPosition;
    }, 0);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  // Determine if the button should be disabled
  const isButtonDisabled =
    cohortName?.trim() === "" || //NOSONAR
    cohortName === undefined ||
    description?.trim() === "" || //NOSONAR
    description === undefined;
  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header className="mx-4 mt-3 px-5 pb-0" closeButton>
        <Modal.Title>
          {saveOperation === "Edit" ? "Edit" : "Save New"} Cohort
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 pb-2">
        <div>
          <p className="fw-semibold mt-1 fs-7 mb-7 w-90 mb-3 text-dark">
            A bank cohort obtained by filtering is a group of banks selected
            based on demographics, asset size, and bank type for comparative
            analysis.
          </p>

          <div className="fw-bolder text-dark fs-5 blockTitle w-100 mt-2 mb-4">
            {saveOperation === "Edit" ? "Edit" : "Save"} Cohort As
          </div>

          <div>
            <Form.Control
              type="text"
              className="login-registration-form form-control mb-2 form-control-lg form-control-solid"
              id="cohort-name"
              aria-describedby="basic-addon3"
              value={cohortName}
              maxLength={"50"}
              onChange={handleNameChange}
            />
            <div className="fw-bolder text-dark fs-5 blockTitle w-100 mt-2 mb-4">
              Description
            </div>
            <Form.Control
              as="textarea"
              rows={3}
              className="login-registration-form form-control mb-2 form-control-lg form-control-solid"
              id="description"
              aria-describedby="basic-addon3"
              value={description}
              maxLength={"200"}
              onChange={handleDescriptionChange}
            />
          </div>
          <div className="d-flex justify-content-between mt-5">
            <div className="fw-bolder text-dark fs-5 blockTitle mt-2">
              Filters Selected
            </div>
            <div className="mt-2">
              <div className="fw-bolder fs-5 text-dark float-end">
                Institutions{" "}
                <span className="fw-normal">
                  {allCohortObj?.originalData?.length > 0 ? allCohortObj?.originalData?.length : 0}
                </span>
              </div>
            </div>
          </div>

          <div className="fw-bolder fs-5 blockTitle mt-2">
            FI Type:{" "}
            <span className="fw-light">
              {FHSExpanded?.fi_type === "bk" ? "Bank" : "Credit Union"}
            </span>
          </div>
          <div className="border-top-0 py-5 float-end">
            <button
              type="button"
              className="btn btn-primary btn-bond fw-bolder fs-5 fs-xxl-6 px-10 py-4"
              onClick={onSave}
              disabled={isButtonDisabled}
            >
              {saveOperation === "Edit" ? "Edit" : "Create"} Cohort
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const SaveCohortSuccessModal = ({ show, onHide, onSave, cohortName }) => { //NOSONAR
  return (
    <Modal
      className="px-3 custom-modalwidth mx-auto"
      show={show}
      onHide={onHide}
      size="md"
      centered
    >
      <Modal.Header className="mx-4 mt-3 px-5 pb-0" closeButton>
        <Modal.Title>{""}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="d-flex flex-column justify-content-center align-items-center pt-0 pb-2"
        style={{ maxHeight: "460px", maxWidth: "460px" }}
      >
        <div className="mb-3">
          <img src={exportimages.success_popup_icon} alt="err" />
        </div>

        <div>
          <p className="fw-bolder mt-1 mb-2 fs-2 mt-1 w-90 text-dark">
            Cohort Saved Successfully!
          </p>
        </div>
        <div>
          <label className="fs-5 fs-xxl-6 mb-5 pb-5">
            {capitalizeFirstLetterOfEachWord(cohortName)}
          </label>
        </div>

        <div className="d-grid gap-2 border-top-0 py-5 col-7 mx-auto">
          <button
            type="button"
            className="btn mb-3 btn-primary btn-bond fw-bolder fs-5 fs-xxl-6 px-20 py-3 w-100"
            onClick={onSave}
          >
            Continue
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
