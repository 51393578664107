import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const bondScoreAnalysisSlice = createApi({
  reducerPath: "analysis_bond_score",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["bond_score"],
  endpoints: (build) => ({
    getBondScoreSummary: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=bond_score&view=summary&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    }),
  }),
});

export const { useGetBondScoreSummaryQuery } = bondScoreAnalysisSlice;
