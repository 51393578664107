import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFhsAvailable: false,
  isPro: false,
  isMarketing: false,
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setIsFhsAvailable: (state, action) => {
      state.isFhsAvailable = action.payload;
    },
    setIsPro: (state, action) => {
      state.isPro = action.payload;
    },
    setIsMarketing: (state, action) => {
      state.isMarketing = action.payload;
    },
  },
});

export const { setIsFhsAvailable, setIsPro, setIsMarketing } =
  subscriptionSlice.actions;

export default subscriptionSlice.reducer;
