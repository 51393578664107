import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { toastResponse } from "../helper components/Toast/Toast";

import { addSecondaryConsumerFilter, setStateDirect } from "./filterOptions";
import { GetGhostConsumerDrilldownData } from "./ghostConsumer";
// import { setOrgId } from '../../../Redux/orgDetailsSlice'
// import { useDispatch } from 'react-redux';
//import { store } from '../../../app/storbhaRequestUide';

const initialState = {
  data: [],
  totalUsers: 0,
  status: false,
  hid: "",
  dataRecived: false,
};
let worker = new Worker("/worker.js");

export const GetSecondaryConsumerDrilldownData = createAsyncThunk(
  "/bank/healthanalysis/secondary",
  async (healthanalysis, thunkAPI) => {
    try {
      const url = process.env.REACT_APP_API_URL;
      let currentState = thunkAPI.getState();
      if (currentState?.loaderReducer?.dataLoading === "Secondary_Consumer") {
        setTimeout(() => {
          thunkAPI.dispatch(GetSecondaryConsumerDrilldownData(healthanalysis));
        }, 10000);
      } else {
        worker.postMessage({
          action: "fetchdata",
          healthanalysis: { ...healthanalysis, type: "SecondaryConsumer" },
          currentState,
          url,
          type: "SecondaryConsumer",
        });
        worker.onmessage = (event) => {
          const { action, data } = event.data;
          if (action === "updateStore") {
            // Dispatch an action to update the Redux store
            thunkAPI.dispatch(setSecondaryConsumerStatus(data.status));
            thunkAPI.dispatch(setSecondaryConsumerTotalUser(data.totalUsers));
          } else if (action === "dataUpdate") {
            thunkAPI.dispatch(
              GetGhostConsumerDrilldownData({
                hid: healthanalysis.hid,
                flag: "initiated",
              }),
            );
          } else if (action === "result") {
            // Handle the final result received from the worker if needed
            thunkAPI.dispatch(setSecondaryConsumerTotalUser(data.totalUsers));
            thunkAPI.dispatch(setSecondaryConsumerData(data.data));
            thunkAPI.dispatch(setSecondaryConsumerStatus(data.status));
            thunkAPI.dispatch(addSecondaryConsumerFilter("Secondary Consumer"));
          } else if (action === "error") {
            thunkAPI.dispatch(setSecondaryConsumerStatus(false));
          }
        };
      }
    } catch (error) {
      console.log(error);
    }
  },
);
export const GetSecondaryConsumerDrilldownDataInRedux = createAsyncThunk(
  "/bank/healthanalysis/secondary/slice",
  async (data, thunkAPI) => {
    worker.postMessage({ action: "getopportunitiesdata" });
    worker.onmessage = (event) => {
      const { action, data } = event.data;
      if (action === "finalresult") {
        thunkAPI.dispatch(setSecondaryConsumerStatus(data.status));
        thunkAPI.dispatch(setStateDirect(data.state));
        thunkAPI.dispatch(addSecondaryConsumerFilter("Secondary Consumer"));
      }
    };
  },
);
export const secondarySlice = createSlice({
  name: "secondary_slice",
  initialState,
  reducers: {
    resetSecondaryConsumerData: (state, action) => {
      // worker.terminate()
      state = initialState;
      worker.terminate(); // Terminate the existing worker
      worker = new Worker("worker.js");
    },
    setSecondaryConsumerData: (state, action) => {
      state.data = action.payload;
    },
    setSecondaryConsumerTotalUser: (state, action) => {
      // console.log("setTotalUsers",action.payload)
      state.totalUsers = action.payload;
    },
    setSecondaryConsumerStatus: (state, action) => {
      state.status = action.payload;
    },
    setSecondaryConsumerHid: (state, action) => {
      state.hid = action.payload;
    },
    getSecondaryConsumerAllData: (state, action) => {},
  },
  extraReducers: {
    [GetSecondaryConsumerDrilldownData.pending]: (state, action) => {},
    [GetSecondaryConsumerDrilldownData.fulfilled]: (state, action) => {},
    [GetSecondaryConsumerDrilldownData.rejected]: (state, action) => {
      toast.error(toastResponse("error", action.error.message));
    },
  },
});

// export const { preSign} = preSign.actions
export const {
  resetData,
  setSecondaryConsumerData,
  getSecondaryConsumerAllData,
  resetSecondaryConsumerData,
  setSecondaryConsumerHid,
  setSecondaryConsumerStatus,
  setSecondaryConsumerTotalUser,
} = secondarySlice.actions;
export default secondarySlice.reducer;
