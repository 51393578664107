import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";

export const toastResponse = (status, message) => {
  if (status === "success") {
    return (
      <div className="p-toast-message-success" id="errordv">
        <div className="p-toast-message-content">
          <div className="p-toast-message-text">
            <span className="p-toast-summary">Successful!</span>
            <div className="p-toast-detail" id="errormsg">
              {message}
            </div>
          </div>
          <div />
        </div>
      </div>
    );
  } else {
    return (
      <div className="p-toast-message-error" id="errordiv">
        <div className="p-toast-message-content">
          <div className="p-toast-message-text">
            <span className="p-toast-summary">Error!</span>
            <div className="p-toast-detail" id="errormessage">
              {message}
            </div>
          </div>
          <div />
        </div>
      </div>
    );
  }
};
