import React, { useState } from "react";
import "./DisplayInstitution.scss";
import { Modal } from "react-bootstrap";
import CardDisplay from "./CardDisplay";
import "./InstitutionUpdationList.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setDisplayAdditionalInsitituteData,
  setOriginalData,
  setRemoveDisplayAdditionalInsitituteData,
} from "../../../Redux/cohortSlice";
import CohortAdditionalInstitutionListDisplay from "./CohortAddidtionalInstitutionListDisplay";
import CohortAdditionalRemovedInstitution from "./CohortAdditionalRemoveInstitution";

const CohortAdditionalDisplayInstitution = React.forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);
  let dispatch = useDispatch();
  const {
    resetFlag,
    inputValueInstitution,
    setInputValueInstitution,
    setSelectedSortInstitution,
    selectedSortInstitution,
    showNotFound,
    cardData,
    resetScrollPositionSelectedInstitution,
    containerHeight
  } = props;
  let removeDisplayAdditionalData = useSelector(
    (state) => state?.cohortSlice?.removeDisplayAdditionalInstituteData,
  );
  let { displayAdditionalInsitituteData, originalData } = useSelector(
    (state) => state?.cohortSlice,
  );
  
  let additionalInstitutionDelete = async (institution) => {
    let updatedData = [...displayAdditionalInsitituteData];
    let newData = updatedData?.filter(
      (item) => item?.idrssd !== institution?.idrssd,
    );
    dispatch(setDisplayAdditionalInsitituteData(newData));

    let originalUpdatedData = [...originalData]
    let newUpdatedData = originalUpdatedData?.filter((item) => item?.idrssd !== institution?.idrssd)
    dispatch(setOriginalData(newUpdatedData))

    let removedUpdatedData = [...removeDisplayAdditionalData];
    let removedNewData = [...removedUpdatedData, institution];
    dispatch(setRemoveDisplayAdditionalInsitituteData(removedNewData));
  };


  let removeInstitutionDelete = (institution) => {
    let updatedData = [...displayAdditionalInsitituteData];
    let newData = [...updatedData, institution];
    dispatch(setDisplayAdditionalInsitituteData(newData));

    let originalUpdatedData = [...originalData]
    let newUpdatedData = [...originalUpdatedData, institution];
    dispatch(setOriginalData(newUpdatedData))

    let removedUpdatedData = [...removeDisplayAdditionalData];
    let removedNewData = removedUpdatedData?.filter(
      (item) => item?.idrssd !== institution?.idrssd,
    );
    dispatch(setRemoveDisplayAdditionalInsitituteData(removedNewData));
  };


  let handleClearForRemovedInstitute = () => {
    let updatedData = [...displayAdditionalInsitituteData];
    updatedData = [...updatedData, ...removeDisplayAdditionalData];
    let updatedAllSelectedData = [...originalData];
    updatedAllSelectedData = [...updatedAllSelectedData, ...removeDisplayAdditionalData];

    dispatch(setOriginalData(updatedAllSelectedData));
    dispatch(setDisplayAdditionalInsitituteData(updatedData));
    dispatch(setRemoveDisplayAdditionalInsitituteData([]));
  };
  

  return (
    <>
      <div className="d-flex flex-column h-100">
        <CohortAdditionalInstitutionListDisplay
          inputValueInstitution={inputValueInstitution}
          setInputValueInstitution={setInputValueInstitution}
          handleDelete={additionalInstitutionDelete}
          ref={ref}
          resetScrollPositionSelectedInstitution={
            resetScrollPositionSelectedInstitution
          }
          showNotFound={showNotFound}
          selectedSortInstitution={selectedSortInstitution}
          setSelectedSortInstitution={setSelectedSortInstitution}
          resetFlag={resetFlag}
          containerHeight={containerHeight}
          style={{ flex: 1 }}
        />
        <div className="d-flex justify-content-between">
          <button
            className="btn btn-link cursor-pointer view-all mt-3 ms-2"
            onClick={() => { setVisible(true); }}
          >
            View All
          </button>
          <div className="d-flex cursor-pointer text-muted mt-3 me-5 p-2">
            {originalData?.length > 0 ? originalData?.length : ""}
          </div>
        </div>
        {removeDisplayAdditionalData?.length !== 0 && (
          <CohortAdditionalRemovedInstitution
            handleDelete={removeInstitutionDelete}
            handleClearForRemovedInstitute={handleClearForRemovedInstitute}
          />
        )}
      </div>
      {visible && (
        <Modal show={visible} size="md" centered>
          <Modal.Body className="pt-0 pb-2 px-0">
            <div>
              <div className="position-relative">
                <CardDisplay
                  data={cardData}
                  cardDisplay={props?.cardDisplay}
                />
                <div className="d-flex justify-content-end modal-close-button">
                  <button className="btn" onClick={() => { setVisible(false); }}>
                    {/* <img src={exportimages?.cohortcloseicon} alt="err" /> */}
                    <i className="pi pi-times modal-close-icon">{""}</i>
                  </button>
                </div>
                <div className="px-5">
                  <CohortAdditionalInstitutionListDisplay
                    inputValueInstitution={inputValueInstitution}
                    setInputValueInstitution={setInputValueInstitution}
                    handleDelete={additionalInstitutionDelete}
                    ref={ref}
                    resetScrollPositionSelectedInstitution={
                      resetScrollPositionSelectedInstitution
                    }
                    visible={visible}
                    showNotFound={showNotFound}
                    selectedSortInstitution={selectedSortInstitution}
                    setSelectedSortInstitution={setSelectedSortInstitution}
                    resetFlag={resetFlag}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
});

export default CohortAdditionalDisplayInstitution;

