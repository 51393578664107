import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const consumerDemographicsAnalysisSlice = createApi({
  reducerPath: "analysis_consumer_demographics",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ["consumer_demographics"],
  endpoints: (build) => ({
    getConsumerDemographicsSummary: build.query({
      query: ({ orgId, headers, payload }) => ({
        url: `bank/${orgId}/dashboard?card=demography_age&view=summary&page=0&size=10`,
        method: "POST",
        headers: headers,
        body: payload,
      }),
    })
  }),
});

export const {
  useGetConsumerDemographicsSummaryQuery
} = consumerDemographicsAnalysisSlice;