import { useState } from "react";
import "./chatbot.component.scss";
import exportimages from "../../../assets/images/exportImages";

export const Chatbot = (props) => {
  const [showChat, setShowChat] = useState(false);

  const fetchShowChatValue = (event) => {
    setShowChat(event.defaultPrevented);
  };

  const fnChatbotClick = () => {
    const script = document.createElement("script");
    script.src = "./Bond_Component.js";
    script.async = true;
    script.onload = () => {
      // The script has been loaded and is now available for use
      // You can call functions or initialize components from the external library here
    };
    document.body.appendChild(script);
    const el = document.querySelector("bond-bot");
    el.addEventListener("recivechatValue", (event) => {
      fetchShowChatValue(event);
    });
    setShowChat(true);
  };

  // useEffect(() => {
  //     //Get value from child to parent
  //     const el = document.querySelector('bond-bot');
  //     el.addEventListener("recivechatValue", (event) => { fetchShowChatValue(event) });
  // }, [])
  return (
    <>
      <div
        style={{
          display:
            process.env.REACT_APP_CHATBOT_IS_ENABLE === "true"
              ? "block"
              : "none",
        }}
      >
        <div
          className="flip-container"
          style={{ display: showChat ? "none" : "block" }}
        >
          <a href onClick={fnChatbotClick}>
            <div className="card border-0 bg-transparent float-right">
              <div className="front">
                <img
                  src={exportimages?.bont_bot_icon_from}
                  className="frontImg"
                  alt=""
                />
                <img
                  src={exportimages?.bont_bot_icon_back}
                  className="backImg"
                  alt=""
                />
              </div>
            </div>
          </a>
        </div>
        <div style={{ display: showChat ? "block" : "none" }}>
          <bond-bot
            isShowChat="true"
            PageName="TestBot"
            RoleName="Client"
            ClientTenantId={`${process.env.REACT_APP_CLIENTTENANTID}`}
          />
        </div>
      </div>
    </>
  );
};
