import { createContext, useContext, useState } from "react";

const chameleonModalContext = createContext({ value: false });

export const useChameleonModalContext = () => useContext(chameleonModalContext);

const ChameleonModalContextProvider = ({ children }) => {
  const [value, setValue] = useState(false);
  const setChameleonValue = (val) => {
    setValue(val);
  };

  return (
    <chameleonModalContext.Provider
      value={{ value, setValue, setChameleonValue }}
    >
      {children}
    </chameleonModalContext.Provider>
  );
};

export default ChameleonModalContextProvider;
