import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { toastResponse } from "../helper components/Toast/Toast";
import { setStateDirect } from "./filterOptions";

const initialState = {
  data: [],
  totalUsers: 0,
  status: false,
  hid: "",
  dataRecived: false,
};
let worker = new Worker("/worker.js");

export const GetGhostConsumerDrilldownData = createAsyncThunk(
  "/bank/healthanalysis/ghostconsumer",
  async (healthanalysis, thunkAPI) => {
    try {
      const url = process.env.REACT_APP_API_URL;
      let currentState = thunkAPI.getState();
      // let tempState = {...currentState,authReducer:{...currentState.authReducer,sessionid:currentState.authReducer?.userData?.sessionid+13221}}
      // // tempState.authReducer.userData.sessionid = tempState.authReducer?.userData?.sessionid+131
      // currentState = tempState
      worker.postMessage({
        action: "fetchdata",
        healthanalysis: { ...healthanalysis, type: "GhostConsumer" },
        currentState,
        url,
        type: "GhostConsumer",
      });
      worker.onmessage = (event) => {
        const { action, data } = event.data;
        if (action === "updateStore") {
          // Dispatch an action to update the Redux store
          thunkAPI.dispatch(setGhostConsumerStatus(data.status));
          thunkAPI.dispatch(setGhostConsumerTotalUser(data.totalUsers));
        } else if (action === "dataUpdate") {
          // thunkAPI.dispatch((GetMissingProductDrilldownData({ hid:healthanalysis.hid, flag: "initiated" })))
        } else if (action === "result") {
          // Handle the final result received from the worker if needed
          thunkAPI.dispatch(setGhostConsumerTotalUser(data.totalUsers));
          thunkAPI.dispatch(setGhostConsumerData(data.data));
          thunkAPI.dispatch(setGhostConsumerStatus(data.status));
        } else if (action === "error") {
          thunkAPI.dispatch(setGhostConsumerStatus(false));
        }
      };
    } catch (error) {
      console.log(error);
    }
  },
);
export const GetGhostConsumerDrilldownDataInRedux = createAsyncThunk(
  "/bank/healthanalysis/ghostconsumer/slice",
  async (data, thunkAPI) => {
    worker.postMessage({ action: "getopportunitiesdata" });
    worker.onmessage = (event) => {
      const { action, data } = event.data;
      if (action === "finalresult") {
        thunkAPI.dispatch(setGhostConsumerStatus(data.status));
        thunkAPI.dispatch(setStateDirect(data.state));
      }
    };
  },
);
export const ghostconsumerSlice = createSlice({
  name: "ghostconsumerSlice",
  initialState,
  reducers: {
    resetGhostConsumerData: (state, action) => {
      // worker.terminate()
      // worker = new Worker("/worker.js")
      // delete window w orker;
      state = initialState;
      worker.terminate(); // Terminate the existing worker
      worker = new Worker("worker.js");
    },
    setGhostConsumerData: (state, action) => {
      state.data = action.payload;
    },
    setGhostConsumerTotalUser: (state, action) => {
      state.totalUsers = action.payload;
    },
    setGhostConsumerStatus: (state, action) => {
      state.status = action.payload;
    },

    setGhostConsumerHid: (state, action) => {
      state.hid = action.payload;
    },
    getGhostConsumerAllData: (state, action) => {},
  },
  extraReducers: {
    [GetGhostConsumerDrilldownData.pending]: (state, action) => {},
    [GetGhostConsumerDrilldownData.fulfilled]: (state, action) => {},
    [GetGhostConsumerDrilldownData.rejected]: (state, action) => {
      toast.error(toastResponse("error", action.error.message));
    },
  },
});

// export const { preSign} = preSign.actions
export const {
  resetData,
  setGhostConsumerData,
  getGhostConsumerAllData,
  resetGhostConsumerData,
  setGhostConsumerHid,
  setGhostConsumerStatus,
  setGhostConsumerTotalUser,
} = ghostconsumerSlice.actions;
export default ghostconsumerSlice.reducer;
