import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hid: null,
  trendId: 0,
  historyDisabled: false,
  intervalId: null,
};

export const mainReportSlice = createSlice({
  name: "mainReport",
  initialState,
  reducers: {
    resetHID: () => initialState,
    setHid: (state, action) => {
      if (action.payload !== state.hid) {
        state.hid = action.payload;
      }
    },
    setTrendId: (state, action) => {
      state.trendId = action.payload;
    },
    setHistoryDisabled: (state, action) => {
      if (state.intervalId && action.payload) {
        state.historyDisabled = true;
      } else if (!action.payload) {
        state.historyDisabled = false;
      }
    },
    setIntervalID: (state, action) => {
      state.intervalId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setHid,
  setTrendId,
  setHistoryDisabled,
  setIntervalID,
  resetHID,
} = mainReportSlice.actions;

export default mainReportSlice.reducer;
